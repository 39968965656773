import React, { createContext, useState, useEffect } from "react";
import { useCookies } from "react-cookie";
import { getUserById } from "../services/userServices";
import { showErrorAlert } from "../components/common/Alert";
import { UNIQUE_INITIAL_ADMIN_ID } from "../config";

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);
  const [cookies] = useCookies(["CURRENT_USER"]);
  const [loading, setLoading] = useState(true);
  const [userType, setUserType] = useState("");
  const [highLight, setHighLight] = useState(null);

  useEffect(() => {
    const fetchUser = async () => {
      if (cookies.CURRENT_USER) {
        try {
          const { userProfile } = await getUserById(cookies.CURRENT_USER);
          setCurrentUser(userProfile);
          setIsAuthenticated(true);

          if (
            userProfile.isAdmin &&
            userProfile._id === UNIQUE_INITIAL_ADMIN_ID
          ) {
            setUserType("initialAdmin");
            setHighLight(UNIQUE_INITIAL_ADMIN_ID);
          } else if (userProfile.isAdmin) {
            setUserType("admin");
          } else {
            setUserType("user");
          }
        } catch (error) {
          showErrorAlert(error);
          setIsAuthenticated(false);
          setCurrentUser(null);
        }
      }
      setLoading(false);
    };

    fetchUser();
  }, [cookies.CURRENT_USER]);

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        currentUser,
        loading,
        setIsAuthenticated,
        setCurrentUser,
        userType,
        highLight,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export { AuthContext, AuthProvider };
