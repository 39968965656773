import React, { createContext, useState, useEffect, useContext } from "react";
import { AuthContext } from "./AuthContext";
import { fetchAllOrders, fetchOrder } from "../services/orderServices";

export const OrderContext = createContext();

export const OrderProvider = ({ children }) => {
  const { isAuthenticated, currentUser } = useContext(AuthContext);
  const [orderDetails, setOrderDetails] = useState([]);
  const [allUsersOrders, setAllUsersOrders] = useState([]);
  const [selectedOrder, setSelectedOrder] = useState({});
  const [isNewOrder, setIsNewOrder] = useState(false);

  useEffect(() => {
    const fetchOrders = async () => {
      if (isAuthenticated && currentUser && !currentUser.isAdmin) {
        const { orderLists } = await fetchOrder(currentUser._id);
        setOrderDetails(orderLists);
      }

      if (isAuthenticated && currentUser && currentUser.isAdmin) {
        const { allUsersOrders } = await fetchAllOrders();
        setAllUsersOrders(allUsersOrders);
      }
    };

    fetchOrders();
  }, [isAuthenticated, currentUser, isNewOrder]);

  const UpdateCurrentUserOrders = (orderId, updatedOrder) => {
    setOrderDetails((prevOrderDetails) => {
      return prevOrderDetails.map((order) => {
        if (order._id === orderId) {
          return {
            ...order,
            status: updatedOrder.status,
          };
        }
        return order;
      });
    });
  };

  const UpdateAllUsersOrders = (selectedOrderId, updatedStatus) => {
    setAllUsersOrders((prevOrderDetails) => {
      return prevOrderDetails.map((userOrders) => {
        return {
          ...userOrders,
          orders: userOrders.orders.map((order) => {
            if (order.orderId === selectedOrderId) {
              return {
                ...order,
                status: updatedStatus,
              };
            }
            return order;
          }),
        };
      });
    });

    setSelectedOrder((prevSelectedOrder) => {
      if (prevSelectedOrder.orderId === selectedOrderId) {
        return {
          ...prevSelectedOrder,
          status: updatedStatus,
        };
      }
      return prevSelectedOrder;
    });
  };

  return (
    <OrderContext.Provider
      value={{
        orderDetails,
        UpdateCurrentUserOrders,
        setOrderDetails,
        allUsersOrders,
        setAllUsersOrders,
        UpdateAllUsersOrders,
        selectedOrder,
        setSelectedOrder,
        setIsNewOrder,
      }}
    >
      {children}
    </OrderContext.Provider>
  );
};
