import React, { createContext, useState, useEffect } from "react";
import { fetchAllProducts } from "../services/productsServices";
import { showErrorAlert } from "../components/common/Alert";
import { DEFAULT_IMAGE } from "../utils/constants";

const ProductContext = createContext({
  activeProductList: [],
  setActiveProductList: () => {},
  inactiveProductList: [],
  setInactiveProductList: () => {},
  updateProduct: () => {},
});

const ProductProvider = ({ children }) => {
  const [activeProductList, setActiveProductList] = useState([]);
  const [inactiveProductList, setInactiveProductList] = useState([]);
  const [triggerUpdate, setTriggerUpdate] = useState(false);
  const [searchKeyword, setSearchKeyword] = useState("");

  const updateProduct = (updatedProduct) => {
    setActiveProductList((prevActiveProducts) => {
      return prevActiveProducts.map((product) => {
        if (product._id === updatedProduct._id) {
          updatedProduct.isUpdated = true;
          return updatedProduct;
        } else {
          return product;
        }
      });
    });

    setInactiveProductList((prevInactiveProducts) => {
      return prevInactiveProducts.map((product) => {
        if (product._id === updatedProduct._id) {
          updatedProduct.isUpdated = true;
          return updatedProduct;
        } else {
          return product;
        }
      });
    });
  };

  useEffect(() => {
    const fetchProducts = async (keyword) => {
      try {
        if (activeProductList.length === 0) {
          const activeProducts = await fetchAllProducts({
            activeAndInactive: false,
            keyword,
          });

          const updatedActiveProducts = activeProducts.products.map(
            (product) => {
              if (!product.imageDetails || product.imageDetails.length === 0) {
                product.imageDetails = [{ location: DEFAULT_IMAGE }];
              }
              return product;
            }
          );

          setActiveProductList(updatedActiveProducts);
        }

        if (inactiveProductList.length === 0) {
          const inactiveProducts = await fetchAllProducts({
            onlyInActive: true,
            keyword,
          });

          const updatedInactiveProducts = inactiveProducts.products.map(
            (product) => {
              if (!product.imageDetails || product.imageDetails.length === 0) {
                product.imageDetails = [{ location: DEFAULT_IMAGE }];
              }
              return product;
            }
          );
          setInactiveProductList(updatedInactiveProducts);
        }
      } catch (error) {
        showErrorAlert(error);
      }
    };

    fetchProducts();
  }, [triggerUpdate, activeProductList.length, inactiveProductList.length]);

  return (
    <ProductContext.Provider
      value={{
        activeProductList,
        setActiveProductList,
        inactiveProductList,
        setInactiveProductList,
        triggerUpdate,
        setTriggerUpdate,
        updateProduct,
        setSearchKeyword,
        searchKeyword,
      }}
    >
      {children}
    </ProductContext.Provider>
  );
};

export { ProductContext, ProductProvider };
