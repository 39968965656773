import React, { useContext, useEffect, useState } from "react";
import {
  Badge,
  Button,
  Card,
  Col,
  Container,
  Image,
  Row,
} from "react-bootstrap";
import { AuthContext } from "../../contexts/AuthContext";
import { deleteAllCartItem, editCartItem } from "../../services/cartServices";
import { ExclamationOctagon, Trash } from "react-bootstrap-icons";
import { Link, useNavigate } from "react-router-dom";
import { showWarningAlert } from "../common/Alert";
import { createOrder } from "../../services/orderServices";
import { CartContext } from "../../contexts/CartContext";
import { showErrorToast, showSuccessToast } from "../common/ToastAlert";
import { OrderContext } from "../../contexts/OrderContext";

export default function CartItems({ customStyle }) {
  const { isAuthenticated, currentUser, userType } = useContext(AuthContext);
  const { currentUserCart, updateCurrentUserCart } = useContext(CartContext);
  const { setOrderDetails, setIsNewOrder } = useContext(OrderContext);
  const [updatedCartItemIds, setUpdatedCartItemIds] = useState([]);
  const [showComponent, setShowComponent] = useState(false);
  const isAdministrator = currentUser.isAdmin;
  const [selectedItems, setSelectedItems] = useState(new Set());
  const [total, setTotal] = useState(0);
  const [selectAllChecked, setSelectAllChecked] = useState(false);
  const style = customStyle ? customStyle : "my-md-5 shadow-lg";
  const navigate = useNavigate();

  useEffect(() => {
    let newTotal = 0;
    for (const itemId of selectedItems) {
      const item = currentUserCart.cartItems.find(
        (item) => item._id === itemId
      );
      if (item) {
        newTotal += item.totalPrice;
      }
    }

    setTotal(newTotal);
  }, [currentUserCart, selectedItems]);

  const toggleComponent = () => {
    const nextState = !showComponent;
    setShowComponent(nextState);

    showSuccessToast(
      nextState
        ? "Behold! The power... just kidding, you still can't add to cart! 😜"
        : "Fear not, Your power is needed elsewhere! 😉"
    );
  };

  const handleUpdateCartItem = async (e, cartItemId, newQuantity) => {
    e.preventDefault();
    try {
      if (isAuthenticated) {
        const { message, updatedCartItems } = await editCartItem(
          currentUser.cartId,
          {
            cartItemId,
            quantity: newQuantity,
          }
        );

        updateCurrentUserCart(updatedCartItems);

        if (!updatedCartItemIds.includes(cartItemId)) {
          setUpdatedCartItemIds([...updatedCartItemIds, cartItemId]);

          setTimeout(() => {
            setUpdatedCartItemIds((prevIds) =>
              prevIds.filter((id) => id !== cartItemId)
            );
          }, 3000);
        }

        showSuccessToast(message);
      }
    } catch (error) {
      showErrorToast(error.message || error);
    }
  };

  const handleConfirmation = () => {
    const title = "Are you sure?";
    const message = "You are about to remove all the items from your cart.";
    showWarningAlert(title, message, handleRemoveAllCartItem);
  };

  const handleRemoveAllCartItem = async () => {
    try {
      if (isAuthenticated) {
        const { message, deleteAllCartItems } = await deleteAllCartItem(
          currentUser.cartId
        );

        showSuccessToast(message);
        updateCurrentUserCart(deleteAllCartItems);
      }
    } catch (error) {
      showErrorToast(error.message || error);
    }
  };

  const handleSelectItem = (itemId, isChecked) => {
    setSelectedItems((prevSelectedItems) => {
      const newSelectedItems = new Set(prevSelectedItems);
      if (isChecked) {
        newSelectedItems.add(itemId);
      } else {
        newSelectedItems.delete(itemId);
      }

      setSelectAllChecked(
        newSelectedItems.size === currentUserCart.cartItems.length
      );
      return newSelectedItems;
    });
  };

  const handleSelectAll = (isChecked) => {
    setSelectAllChecked(isChecked);
    setSelectedItems(() => {
      if (isChecked) {
        return new Set(currentUserCart.cartItems.map((item) => item._id));
      } else {
        return new Set();
      }
    });
  };

  const handleCheckout = async () => {
    try {
      if (isAuthenticated) {
        const selectedItemsArray = Array.from(selectedItems).map((itemId) => {
          const item = currentUserCart.cartItems.find(
            (item) => item._id === itemId
          );
          return item;
        });

        const orderData = {
          cartId: currentUser.cartId,
          selectedItemsArray,
        };

        const { message, remainingItems, orders } = await createOrder(
          orderData
        );
        setOrderDetails((prevOrderDetails) => [...prevOrderDetails, orders]);
        setIsNewOrder(true);

        updateCurrentUserCart(remainingItems.cartItems);
        setSelectedItems(new Set());
        setSelectAllChecked(false);
        navigate(`/${userType}/dashboard/${currentUser._id}/orders`);
        showSuccessToast(message);
      }
    } catch (error) {
      showErrorToast(error.message || error);
    }
  };

  return (
    <Container
      className={`bg-white rounded ${style}`}
      style={{ minHeight: "500px" }}
    >
      {isAdministrator && (
        <Button
          className="border-0 bg-white text-danger"
          onClick={toggleComponent}
        >
          <ExclamationOctagon />
        </Button>
      )}

      {(showComponent && isAdministrator) || !isAdministrator ? (
        <>
          <Row className="bg-white rounded">
            <div className="d-flex justify-content-between align-items-center py-md-3 px-md-5 border-bottom">
              <h5 className="m-0 text-muted">
                Cart Items: {currentUserCart.cartItems?.length}
              </h5>
              <Button
                variant="danger"
                className="border-0 align-self-end"
                onClick={handleConfirmation}
              >
                Remove All
              </Button>
            </div>
            <div className="css-scroll px-md-5 pt-md-4">
              {currentUserCart.cartItems.map((item) => (
                <Card className="mb-3 p-0" key={item._id}>
                  <Card.Header className="d-flex justify-content-between align-items-center p-1">
                    <label
                      htmlFor="checkToCheckout"
                      className="css-style d-flex"
                    >
                      <input
                        type="checkbox"
                        id="checkToCheckout"
                        className="mx-3"
                        checked={selectedItems.has(item._id)}
                        onChange={(e) =>
                          handleSelectItem(item._id, e.target.checked)
                        }
                      />
                      <h6 className="m-0">{item.productBrand}</h6>
                    </label>
                    <Button
                      variant="outline-danger"
                      className="border-0 align-self-end"
                      onClick={(e) => handleUpdateCartItem(e, item._id)}
                    >
                      <Trash />
                    </Button>
                  </Card.Header>
                  <Card.Body>
                    <Row className="d-flex justify-content-between align-items-center">
                      <Col md={2}>
                        <Image
                          variant="top"
                          src={item.imageUrl}
                          fluid
                          className="rounded border d-block m-auto"
                          style={{ height: "70px" }}
                        />
                      </Col>
                      <Col md={8}>
                        <Link
                          to={`/products/${item.productId}`}
                          className="text-decoration-none text-dark"
                        >
                          <h6 className="css-hover">{item.productName}</h6>
                        </Link>

                        <Card.Text className="mb-1">
                          Price: {item.productPrice}
                        </Card.Text>
                        <Card.Text className="mb-1">
                          Total Price: {item.totalPrice}
                          {updatedCartItemIds.includes(item._id) && (
                            <Badge
                              bg="white"
                              className="mx-2 text-danger p-0"
                              style={{ fontSize: "1rem" }}
                            >
                              !
                            </Badge>
                          )}
                        </Card.Text>
                      </Col>
                      <Col md={2} className="d-flex flex-column text-center">
                        <Card.Text className="m-0">Quantity</Card.Text>
                        <div className="d-flex border rounded my-md-2">
                          <Button
                            variant="light"
                            onClick={(e) =>
                              handleUpdateCartItem(
                                e,
                                item._id,
                                Math.max(1, item.quantity - 1)
                              )
                            }
                          >
                            -
                          </Button>
                          <input
                            style={{ width: "40px" }}
                            className="border-0 text-center bg-white mx-auto"
                            type="number"
                            min="1"
                            value={item.quantity}
                            onChange={(e) =>
                              handleUpdateCartItem(
                                e,
                                item._id,
                                Math.max(1, parseInt(e.target.value))
                              )
                            }
                          />
                          <Button
                            variant="light"
                            onClick={(e) =>
                              handleUpdateCartItem(
                                e,
                                item._id,
                                item.quantity + 1
                              )
                            }
                          >
                            +
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              ))}
              {currentUserCart.cartItems.length === 0 ? (
                <h4 className="text-center text-muted my-md-5">
                  Your cart is empty.
                </h4>
              ) : null}
            </div>
          </Row>
          <Row className="sticky-bottom bg-light border rounded p-md-4">
            <Col className="d-flex justify-content-between align-items-center p-0">
              <Col htmlFor="checkToCheckout" className="d-flex">
                <input
                  type="checkbox"
                  id="checkToCheckout"
                  className="mx-3"
                  checked={selectAllChecked}
                  onChange={(e) => handleSelectAll(e.target.checked)}
                />
                <h6 className="m-0">Select All</h6>
              </Col>
              <Col className="col-md-4 d-flex justify-content-between align-items-center">
                <h6 className="m-0">{`Total (${selectedItems.size} ${
                  selectedItems.size <= 1 ? "item" : "items"
                }): ${total}`}</h6>
                <Button
                  variant="success"
                  style={{ width: "40%" }}
                  onClick={handleCheckout}
                >
                  Checkout
                </Button>
              </Col>
            </Col>
          </Row>
        </>
      ) : (
        isAdministrator && (
          <div className="bg-white" style={{ height: "100px" }}>
            <p className="text-center text-muted bg-white m-md-5">
              This feature isn't available for admins, but hey, if you want to
              take a walk on the wild side and experience your own shop as a
              mere mortal, give this{" "}
              <ExclamationOctagon className="text-danger mx-2" /> a click!
            </p>
          </div>
        )
      )}
    </Container>
  );
}
