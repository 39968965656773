import React, { useContext, useEffect, useState } from "react";
import {
  Modal,
  Form,
  Button,
  Image,
  Row,
  Col,
  Container,
} from "react-bootstrap";
import { XLg } from "react-bootstrap-icons";
import { deleteUserProfile, getUserById } from "../../services/userServices";
import { UserUpdateContext } from "../../contexts/UserUpdateContext";
import { showErrorAlert, showSuccessAlert } from "../common/Alert";

export default function ProfilesUserModals({
  selectedUser,
  showUserModal,
  setShowUserModal,
  initialAdminId,
}) {
  const { forceUpdate, handleForceUpdate } = useContext(UserUpdateContext);
  const [currentUser, setCurrentUser] = useState({
    _id: "",
    firstName: "",
    lastName: "",
    mobileNo: "",
    address: "",
    email: "",
    isAdmin: "",
    cartId: "",
  });

  const isInitialAdmin = currentUser._id === initialAdminId;

  const [showConfirmModal, setShowConfirmModal] = useState(false);

  useEffect(() => {
    const fetchUserProfile = async () => {
      if (selectedUser) {
        const { userProfile } = await getUserById(selectedUser._id);
        setCurrentUser(userProfile);
      }
    };

    fetchUserProfile();
  }, [selectedUser, setCurrentUser, forceUpdate]);

  function handleDeleteClick() {
    setShowConfirmModal(true);
  }

  async function handleDeleteUser() {
    try {
      const { message } = await deleteUserProfile(currentUser._id);
      setShowUserModal(false);
      setShowConfirmModal(false);
      showSuccessAlert(message);
      handleForceUpdate(currentUser._id);
    } catch (error) {
      showErrorAlert(error);
    }
  }

  return (
    <Container>
      <Modal
        show={showUserModal}
        onHide={() => setShowUserModal(false)}
        size="lg"
        centered
      >
        <Modal.Header>
          <div>
            <Modal.Title>
              {currentUser.isAdmin
                ? "Your Highness - sama!"
                : `User ${currentUser.firstName} - sama`}
            </Modal.Title>
            {currentUser.isAdmin ? (
              <p className="m-0 mt-2 text-muted" style={{ fontSize: "0.8rem" }}>
                "Hail to thee, supreme overlord! The digital realm is at your
                command!😎👑"
              </p>
            ) : (
              <p className="m-0 text-muted" style={{ fontSize: "0.8rem" }}>
                "Greetings, adventurous User! Your epic journey of fun and
                laughter starts now! 🥳🚀"
              </p>
            )}
          </div>
          <Button
            variant="outline-danger"
            onClick={() => setShowUserModal(false)}
            className="fw-semibold border-0"
          >
            <XLg /> Cancel
          </Button>
        </Modal.Header>
        <Modal.Body>
          <Row className="p-md-3">
            <Col className="col-md-3 col-12">
              <div className="d-flex flex-column justify-content-evenly align-items-center my-auto">
                <Image
                  src={
                    currentUser.imageDetails &&
                    currentUser.imageDetails.location
                      ? currentUser.imageDetails.location
                      : "../../../../images/icon.png"
                  }
                  fluid
                  className="rounded-circle border mx-auto shadow-sm"
                />
                <h6 className="my-md-3 mt-2">{`${currentUser.firstName} ${currentUser.lastName}`}</h6>
                {isInitialAdmin && currentUser.isAdmin ? (
                  <p className="text-danger">Initial Admin</p>
                ) : currentUser.isAdmin ? (
                  <p className="text-danger">Admin</p>
                ) : (
                  <p className="text-primary">User</p>
                )}
                {isInitialAdmin ? (
                  <Button
                    variant="white"
                    className="text-danger border-0"
                    disabled
                  >
                    Unable to remove initial admin.
                  </Button>
                ) : (
                  <Button variant="danger" onClick={handleDeleteClick}>
                    Delete {currentUser.isAdmin ? "Admin" : "User"}
                  </Button>
                )}
              </div>
            </Col>
            <Col className="col-md-9 col-12">
              <Form>
                <Form.Floating className="mb-1">
                  <Form.Control
                    className="border-0 border-bottom rounded-0 bg-white ps-md-4"
                    type="text"
                    placeholder="UserId"
                    value={currentUser._id}
                    disabled
                  />
                  <Form.Label>UserId</Form.Label>
                </Form.Floating>

                <Form.Floating className="mb-1">
                  <Form.Control
                    className="border-0 border-bottom rounded-0 bg-white ps-md-4"
                    type="text"
                    placeholder="CartId"
                    value={currentUser.cartId}
                    disabled
                  />
                  <Form.Label>CartId</Form.Label>
                </Form.Floating>

                <Form.Floating className="my-1">
                  <Form.Control
                    className="border-0 border-bottom rounded-0 bg-white ps-md-4"
                    type="text"
                    placeholder="Mobile Number"
                    value={currentUser.mobileNo}
                    disabled
                  />
                  <Form.Label>Mobile Number</Form.Label>
                </Form.Floating>

                <Form.Floating className="my-1">
                  <Form.Control
                    className="border-0 border-bottom rounded-0 bg-white ps-md-4"
                    type="text"
                    placeholder="Address"
                    value={currentUser.address}
                    disabled
                  />
                  <Form.Label>Address</Form.Label>
                </Form.Floating>

                <Form.Floating className="my-1">
                  <Form.Control
                    className="border-0 border-bottom rounded-0 bg-white ps-md-4"
                    type="email"
                    placeholder="Email Address"
                    value={currentUser.email}
                    disabled
                  />
                  <Form.Label>Email address</Form.Label>
                </Form.Floating>
              </Form>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
      <Modal
        show={showConfirmModal}
        onHide={() => setShowConfirmModal(false)}
        centered
        className="bg-dark bg-opacity-75"
      >
        <Modal.Header>
          <Modal.Title>Confirm Delete</Modal.Title>
          <Button
            variant="outline-danger"
            className="border-0"
            onClick={() => setShowConfirmModal(false)}
          >
            <XLg /> Cancel
          </Button>
        </Modal.Header>
        <Modal.Body className="text-center my-2">
          <h6>Are you sure you want to delete user</h6>
          <h5 className="text-primary">{`${currentUser.firstName} ${currentUser.lastName}`}</h5>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="outline-success"
            className="border-0 mx-auto"
            onClick={handleDeleteUser}
          >
            Confirm Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
}
