import React from "react";
import { Form, Button, Container, Row, Col, Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Facebook, Google } from "react-bootstrap-icons";
import { Eye, EyeSlash } from "react-bootstrap-icons";
import { useState } from "react";
import { DEFAULT_IMAGE } from "../../utils/constants";

export default function LoginForm({
  onSubmit,
  onChange,
  userData,
  rememberMe,
  handleRememberMeChange,
}) {
  const [showPassword, setShowPassword] = useState(true);
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <Container>
      <Row className="p-md-5 d-flex justify-content-between my-5 m-md-0">
        <Col className="col-md-6 d-flex flex-fill col-12 px-0 px-md-2 mb-2 mb-md-0">
          <Image
            src={DEFAULT_IMAGE}
            fluid
            className="rounded border shadow-sm"
          />
        </Col>
        <Col className="col-md-6 col-12 text-center d-flex flex-column justify-content-between p-0 px-md-2">
          <h3>Log In</h3>
          <hr />
          <div className="d-flex justify-content-evenly my-2">
            <Button variant="light" className="w-100 me-2 shadow-sm">
              <Facebook className="me-2" />
              Facebook
            </Button>
            <Button variant="light" className="w-100 shadow-sm">
              <Google className="me-2" />
              Google
            </Button>
          </div>
          <p className="fw-semibold my-2">Or</p>
          <Form
            onSubmit={onSubmit}
            className="d-flex flex-column border py-3 px-4 rounded bg-white shadow-sm"
          >
            <Form.Floating className="my-1">
              <Form.Control
                className="border-0 border-bottom rounded-0"
                type="email"
                placeholder="Email Address"
                name="email"
                value={userData.email}
                onChange={onChange}
                required
              />
              <Form.Label>Email address</Form.Label>
            </Form.Floating>

            <Form.Floating className="my-1">
              <Form.Control
                className="border-0 border-bottom rounded-0"
                type={showPassword ? "password" : "text"}
                placeholder="Password"
                name="password"
                value={userData.password}
                onChange={onChange}
                required
              />
              <Form.Label>Password</Form.Label>
              <div
                onClick={togglePasswordVisibility}
                className="position-absolute top-50 end-0 me-3 translate-middle-y border-0"
                style={{ cursor: "pointer", zIndex: 2, fontSize: "1.5rem" }}
              >
                {showPassword ? (
                  <EyeSlash className="text-secondary fw-bolder" />
                ) : (
                  <Eye className="text-dark fw-bolder" />
                )}
              </div>
            </Form.Floating>

            <Form.Check
              type="checkbox"
              className="my-1 align-self-start my-2"
              label="Remember me"
              checked={rememberMe}
              onChange={handleRememberMeChange}
            />

            <Button variant="light" type="submit" className="my-4">
              Log In
            </Button>

            <p
              className="text-muted text-center mb-0"
              style={{ fontSize: "0.9rem" }}
            >
              Don't have an account yet?{" "}
              <Link to="/signup" className="h6 text-decoration-none">
                Sign up
              </Link>
            </p>
          </Form>
        </Col>
      </Row>
    </Container>
  );
}
