import React, { useContext } from "react";
import { Button, Col, Image, Modal, Row } from "react-bootstrap";
import {
  Hourglass,
  HourglassBottom,
  HourglassSplit,
  HourglassTop,
  SlashCircle,
  XLg,
} from "react-bootstrap-icons";
import { AuthContext } from "../../contexts/AuthContext";
import { showWarningAlert } from "../common/Alert";
import { updateOrder } from "../../services/orderServices";
import { OrderContext } from "../../contexts/OrderContext";
import { showErrorToast, showSuccessToast } from "../common/ToastAlert";

export default function OrderStatusModals({
  showStatusModal,
  setShowStatusModal,
}) {
  const { isAuthenticated } = useContext(AuthContext);
  const { UpdateAllUsersOrders, selectedOrder } = useContext(OrderContext);

  const handleStatusConfirmation = (newStatus) => {
    const title = "Are you sure?";
    const message = "You are about to change the status order.";
    showWarningAlert(title, message, () => handleUpdateStatus(newStatus));
  };

  const handleUpdateStatus = async (newStatus) => {
    try {
      if (isAuthenticated) {
        const data = {
          orderId: selectedOrder.orderId,
          newStatus,
        };
        const { message, updatedOrder } = await updateOrder(data);

        UpdateAllUsersOrders(selectedOrder.orderId, updatedOrder.status);
        showSuccessToast(message);
      }
    } catch (error) {
      showErrorToast(error.message || error);
    }
  };

  return (
    <Modal
      show={showStatusModal}
      onHide={() => setShowStatusModal(false)}
      size="lg"
      centered
      scrollable={true}
    >
      <Modal.Header className="p-2">
        <h5 className="m-0">Update Order Status</h5>
        <Button
          variant="outline-danger"
          className="border-0"
          onClick={() => setShowStatusModal(false)}
        >
          <XLg />
        </Button>
      </Modal.Header>
      <Modal.Header className="d-flex flex-column p-0">
        <p className="my-2">Status Options:</p>
        <Row className="w-100 d-flex justify-content-between p-3 pt-0 align-items-center">
          <Col
            md={2}
            className="border rounded p-2 mb-2 mb-md-0 d-flex flex-md-column justify-content-between align-items-center"
          >
            <p
              className="mb-md-2 mb-0 text-muted d-flex justify-content-center align-items-center"
              style={{ fontSize: "0.8rem" }}
            >
              PENDING: <Hourglass className="ms-1 text-secondary" />
            </p>
            <Button
              variant={`${
                selectedOrder.status === "PENDING"
                  ? "secondary"
                  : "outline-secondary"
              }`}
              className="rounded-circle border-0 shadow-sm"
              onClick={() => handleStatusConfirmation("PENDING")}
            >
              <Hourglass />
            </Button>
          </Col>
          <Col
            md={2}
            className="border rounded p-2 mb-2 mb-md-0 d-flex flex-md-column justify-content-between align-items-center"
          >
            <p
              className="mb-md-2 mb-0 text-muted d-flex justify-content-center align-items-center"
              style={{ fontSize: "0.8rem" }}
            >
              PROCESSED: <Hourglass className="ms-1 text-info" />
            </p>
            <Button
              variant={`${
                selectedOrder.status === "PROCESSED" ? "info" : "outline-info"
              }`}
              className={`${
                selectedOrder.status === "PROCESSED"
                  ? "text-white"
                  : "text-info"
              } rounded-circle border-0 shadow-sm`}
              onClick={() => handleStatusConfirmation("PROCESSED")}
            >
              <HourglassTop />
            </Button>
          </Col>
          <Col
            md={2}
            className="border rounded p-2 mb-2 mb-md-0 d-flex flex-md-column justify-content-between align-items-center"
          >
            <p
              className="mb-md-2 mb-0 text-muted d-flex justify-content-center align-items-center"
              style={{ fontSize: "0.8rem" }}
            >
              SHIPPED: <Hourglass className="ms-1 text-primary" />
            </p>
            <Button
              variant={`${
                selectedOrder.status === "SHIPPED"
                  ? "primary"
                  : "outline-primary"
              }`}
              className="rounded-circle border-0 shadow-sm"
              onClick={() => handleStatusConfirmation("SHIPPED")}
            >
              <HourglassSplit />
            </Button>
          </Col>
          <Col
            md={2}
            className="border rounded p-2 mb-2 mb-md-0 d-flex flex-md-column justify-content-between align-items-center"
          >
            <p
              className="mb-md-2 mb-0 text-muted d-flex justify-content-center align-items-center"
              style={{ fontSize: "0.8rem" }}
            >
              DELIVERED: <Hourglass className="ms-1 text-success" />
            </p>
            <Button
              variant={`${
                selectedOrder.status === "DELIVERED"
                  ? "success"
                  : "outline-success"
              }`}
              className="rounded-circle border-0 shadow-sm"
              onClick={() => handleStatusConfirmation("DELIVERED")}
            >
              <HourglassBottom />
            </Button>
          </Col>
          <Col
            md={2}
            className="border rounded p-2 mb-2 mb-md-0 d-flex flex-md-column justify-content-between align-items-center"
          >
            <p
              className="mb-md-2 mb-0 text-muted d-flex justify-content-center align-items-center"
              style={{ fontSize: "0.8rem" }}
            >
              CANCELLED: <Hourglass className="ms-1 text-danger" />
            </p>
            <Button
              variant={`${
                selectedOrder.status === "CANCELLED"
                  ? "danger"
                  : "outline-danger"
              }`}
              className="rounded-circle border-0 shadow-sm"
              onClick={() => handleStatusConfirmation("CANCELLED")}
            >
              <SlashCircle />
            </Button>
          </Col>
        </Row>
      </Modal.Header>
      <Modal.Body className="p-0">
        <Col className="d-flex flex-md-row flex-column justify-content-between align-items-center p-md-3 p-2">
          <h6 className="m-md-0">Purchased Products:</h6>
          <h6
            className={`m-md-0 ${
              selectedOrder.status === "PENDING"
                ? "text-secondary"
                : selectedOrder.status === "PROCESSED"
                ? "text-info"
                : selectedOrder.status === "SHIPPED"
                ? "text-primary"
                : selectedOrder.status === "DELIVERED"
                ? "text-success"
                : "text-danger"
            }`}
          >
            {selectedOrder.status}
          </h6>
          <div className="d-flex align-items-center m-0">
            <p className="m-0" style={{ fontSize: "0.8rem" }}>
              {`Total (${selectedOrder.purchasedProducts?.length} ${
                selectedOrder.purchasedProducts?.length <= 1 ? "item" : "items"
              }):`}
            </p>
            <h5 className="m-0 ms-2">{`₱ ${selectedOrder.totalPurchasedAmount}`}</h5>
          </div>
        </Col>
        <div>
          {selectedOrder.purchasedProducts &&
            selectedOrder.purchasedProducts.map((item) => (
              <Row key={item._id} className="m-0 m-3 mt-0 border rounded">
                <Col className="col-12 p-1 border-bottom">
                  <p className="fw-semibold m-0">{item.productBrand}</p>
                </Col>
                <Col className="col-12 d-flex flex-md-row flex-column justify-content-between align-items-md-center p-0">
                  <div className="d-flex col-md-10 p-1">
                    <div className="col-md-2 d-flex flex-fill col-6">
                      <Image
                        src={item.imageUrl}
                        fluid
                        className="border rounded"
                      />
                    </div>
                    <div className="ps-2 col-md-10 col-6">
                      <p className="m-0">{item.productName}</p>
                      <p
                        className="m-0 text-muted"
                        style={{ fontSize: "0.8rem" }}
                      >
                        From: {item.manufacturerAddress}
                      </p>
                      <p className="m-0">x{item.quantity}</p>
                    </div>
                  </div>
                  <hr className="m-0 text-muted" />
                  <h6 className="text-muted text-end text-md-center my-1 my-md-0 me-2 me-md-0 col-md-2 m-0">
                    ₱ {item.totalPrice}
                  </h6>
                </Col>
              </Row>
            ))}
        </div>
      </Modal.Body>
    </Modal>
  );
}
