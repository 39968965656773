import React, { useContext, useEffect, useState } from "react";
import { Button, Container, Modal, Table } from "react-bootstrap";
import {
  ArrowRight,
  EmojiNeutralFill,
  EmojiSunglassesFill,
  PersonFillGear,
  PersonVcardFill,
  XLg,
} from "react-bootstrap-icons";
import { editUserProfile, getAllUsers } from "../../services/userServices";
import { AuthContext } from "../../contexts/AuthContext";
import SearchBar from "../common/SearchBar";
import { showErrorAlert, showSuccessAlert } from "../common/Alert";
import ProfilesListEditModals from "./ProfilesListEditModals";
import ProfilesUserModals from "./ProfilesUserModals";
import { UserUpdateContext } from "../../contexts/UserUpdateContext";
import { showErrorToast } from "../common/ToastAlert";
import { useLogout } from "../../utils/useLogout";

export default function ProfilesList() {
  const { forceUpdate, updatedUsers, handleForceUpdate, removeUpdatedUser } =
    useContext(UserUpdateContext);
  const { isAuthenticated, highLight, currentUser, userType } =
    useContext(AuthContext);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [showEditUserModal, setShowEditUserModal] = useState(false);
  const [showUserModal, setShowUserModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [allUsers, setAllUsers] = useState([]);
  const forceLogout = useLogout();

  useEffect(() => {
    const fetchAllUsersProfile = async (keyword) => {
      const { listOfUsers } = await getAllUsers({
        keyword,
      });
      setAllUsers(listOfUsers);

      if (searchKeyword !== "") {
        const filteredUsers = listOfUsers.filter((user) => {
          const regex = new RegExp(searchKeyword, "i");
          return (
            regex.test(user.firstName) ||
            regex.test(user.lastName) ||
            regex.test(user.email) ||
            regex.test(user.address)
          );
        });
        setAllUsers(filteredUsers);
      }
    };

    fetchAllUsersProfile(searchKeyword);
  }, [isAuthenticated, searchKeyword, forceUpdate]);

  const handleSearch = (searchValue) => {
    setSearchKeyword(searchValue);
  };

  const handleIsAdmin = async () => {
    try {
      const updatedSelectedUser = {
        ...selectedUser,
        isAdmin: !selectedUser.isAdmin,
      };
      const { message, updatedUser } = await editUserProfile(
        selectedUser._id,
        updatedSelectedUser
      );

      setShowConfirmationModal(false);
      showSuccessAlert(message);
      handleForceUpdate(updatedUser._id);
      if (
        selectedUser._id === currentUser._id &&
        userType === "admin" &&
        updatedUser.isAdmin === false
      ) {
        showErrorToast(
          "Your admin privileges have been revoked. You will be logged out automatically. Please log in again."
        );
        forceLogout();
      }
    } catch (error) {
      showErrorAlert(error);
    }
  };

  const handleShowConfirmationModal = (user) => {
    setSelectedUser(user);
    setShowConfirmationModal(true);
    removeUpdatedUser(user._id);
  };

  const handleShowEditUserModal = (user) => {
    setSelectedUser(user);
    setShowEditUserModal(true);
    removeUpdatedUser(user._id);
  };

  const handleShowUserModal = (user) => {
    setSelectedUser(user);
    setShowUserModal(true);
    removeUpdatedUser(user._id);
  };

  const initialAdmin = allUsers.find((user) => user.isAdmin);
  const initialAdminId = initialAdmin ? initialAdmin._id : null;

  return (
    <Container className="p-md-3 bg-white">
      <div className="d-flex">
        <p style={{ fontSize: "0.9rem" }} className="fw-semibold me-md-3">
          Admin: {allUsers.filter((user) => user.isAdmin).length}
        </p>
        <p style={{ fontSize: "0.9rem" }} className="fw-semibold">
          Users: {allUsers.filter((user) => !user.isAdmin).length}
        </p>
      </div>

      <SearchBar
        onSearch={handleSearch}
        placeholder={
          "Search Keyword: User's first name, last name, email or address."
        }
      />

      <div className="css-scroll my-3 border rounded">
        <Table hover responsive>
          <thead className="text-center">
            <tr>
              <th>News</th>
              <th>isAdmin</th>
              <th>UserId</th>
              <th>UserName</th>
              <th>Edit User</th>
              <th>View User</th>
            </tr>
          </thead>
          <tbody className="text-center">
            {allUsers.map((user) => (
              <tr
                key={user._id}
                className={
                  user._id === highLight ? "css-initialadmin" : "css-user"
                }
              >
                <td>
                  {updatedUsers[user._id] && (
                    <h6 className="m-0 badge bg-success my-2 py-1 text-white">
                      Updated
                    </h6>
                  )}
                </td>
                <td>
                  {user.isAdmin ? (
                    <Button
                      variant="light"
                      className="border-0 rounded-circle text-danger shadow-sm"
                      onClick={() => handleShowConfirmationModal(user)}
                    >
                      <EmojiSunglassesFill />
                    </Button>
                  ) : (
                    <Button
                      variant="light"
                      className="border-0 rounded-circle text-secondary shadow-sm"
                      onClick={() => handleShowConfirmationModal(user)}
                    >
                      <EmojiNeutralFill />
                    </Button>
                  )}
                </td>
                <td>{user._id}</td>
                <td>{`${user.firstName} ${user.lastName}`}</td>
                <td>
                  <Button
                    variant="light"
                    className="border-0 rounded-circle shadow-sm"
                    onClick={() => handleShowEditUserModal(user)}
                  >
                    <PersonFillGear />
                  </Button>
                </td>
                <td>
                  <Button
                    variant="light"
                    className="border-0 rounded-circle shadow-sm"
                    onClick={() => handleShowUserModal(user)}
                  >
                    <PersonVcardFill />
                  </Button>
                </td>
              </tr>
            ))}
            {allUsers.length === 0 && (
              <tr>
                <td colSpan="6" className="text-center">
                  No Users found.
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>
      <Modal
        show={showConfirmationModal}
        onHide={() => setShowConfirmationModal(false)}
        centered
      >
        <Modal.Header>
          <Modal.Title>Confirm Action</Modal.Title>
          <Button
            variant="outline-danger"
            className="border-0"
            onClick={() => setShowConfirmationModal(false)}
          >
            <XLg /> Cancel
          </Button>
        </Modal.Header>
        <Modal.Body className="text-center">
          {selectedUser &&
            (selectedUser.isAdmin ? (
              <>
                <h6 className="mb-md-3">
                  {selectedUser._id === currentUser._id
                    ? "Are you sure you want to be a User?"
                    : `Make ${selectedUser.firstName} ${selectedUser.lastName} -
                  sama `}
                </h6>
                <div className="d-flex justify-content-evenly align-items-center w-50 mx-auto">
                  <h5 className="text-danger m-0">"Admin"</h5>
                  <ArrowRight
                    className="text-success"
                    style={{ fontSize: "1.5rem" }}
                  />
                  <h5 className="text-primary m-0">"User"</h5>
                </div>
              </>
            ) : (
              <>
                <h6 className="mb-md-3">
                  {`Make ${selectedUser.firstName} ${selectedUser.lastName} -
                  sama `}
                </h6>
                <div className="d-flex justify-content-evenly align-items-center w-50 mx-auto">
                  <h5 className="text-primary m-0">"User"</h5>
                  <ArrowRight
                    className="text-success"
                    style={{ fontSize: "1.5rem" }}
                  />
                  <h5 className="text-danger m-0">"Admin"</h5>
                </div>
              </>
            ))}
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-center">
          {allUsers.indexOf(selectedUser) === 0 ? (
            <Button variant="white" className="border-0 text-danger" disabled>
              Initial admin cannot be removed.
            </Button>
          ) : (
            <Button
              variant="outline-success"
              className="border-0 w-25"
              onClick={handleIsAdmin}
            >
              Confirm
            </Button>
          )}
        </Modal.Footer>
      </Modal>
      <ProfilesListEditModals
        selectedUser={selectedUser}
        showEditUserModal={showEditUserModal}
        setShowEditUserModal={setShowEditUserModal}
      />
      <ProfilesUserModals
        initialAdminId={initialAdminId}
        selectedUser={selectedUser}
        showUserModal={showUserModal}
        setShowUserModal={setShowUserModal}
      />
    </Container>
  );
}
