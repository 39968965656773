import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { fetchSingleProduct } from "../services/productsServices";
import {
  Container,
  Row,
  Col,
  Carousel,
  Button,
  InputGroup,
  FormControl,
  Image,
} from "react-bootstrap";
import { Cart2 } from "react-bootstrap-icons";
import { AuthContext } from "../contexts/AuthContext";
import { addToCart } from "../services/cartServices";
import { ProductContext } from "../contexts/ProductContext";
import {
  showErrorToast,
  showSuccessToast,
} from "../components/common/ToastAlert";
import { CartContext } from "../contexts/CartContext";

export default function Product() {
  const { isAuthenticated, currentUser } = useContext(AuthContext);
  const { updateCurrentUserCart } = useContext(CartContext);
  const { productId } = useParams();
  const { activeProductList, triggerUpdate, setTriggerUpdate } =
    useContext(ProductContext);
  const [product, setProduct] = useState(null);
  const [quantity, setQuantity] = useState(1);
  const navigate = useNavigate();

  useEffect(() => {
    const getProduct = () => {
      const foundProduct = activeProductList.find(
        (product) => product._id === productId
      );
      if (foundProduct) {
        setProduct(foundProduct);
      } else {
        fetchProduct();
      }
    };

    const fetchProduct = async () => {
      try {
        const { singleProduct } = await fetchSingleProduct(productId);
        setProduct(singleProduct);
      } catch (error) {
        showErrorToast(error.message || error);
      }
    };

    getProduct();
  }, [productId, activeProductList]);

  const handleAddToCart = async () => {
    try {
      if (isAuthenticated) {
        const itemToAdd = {
          productId,
          quantity,
        };
        const { message, cartItems } = await addToCart(
          currentUser.cartId,
          itemToAdd
        );

        setQuantity(1);
        updateCurrentUserCart(cartItems);
        showSuccessToast(message);
        setTriggerUpdate(!triggerUpdate);
      } else {
        showErrorToast("Sign Up or Log In to add products to cart.");
        navigate("/signup");
      }
    } catch (error) {
      showErrorToast(error.message || error);
    }
  };

  const updateQuantity = (value) => {
    const newQuantity = Math.max(1, quantity + value);
    setQuantity(newQuantity);
  };

  return (
    <>
      <Container className="my-5 m-0 p-0">
        {product ? (
          <Row className="product-view p-2 justify-content-center m-0">
            <Col className="p-md-3 col-lg-6 col-12 d-flex justify-content-center align-items-center">
              <Carousel className="rounded shadow border bg-light">
                {product &&
                  product.imageDetails &&
                  product.imageDetails.map((image, index) => (
                    <Carousel.Item
                      key={index}
                      className="productViewimage rounded bg-light"
                    >
                      <div className="d-flex justify-content-center w-100 h-100 rounded bg-light">
                        <Image
                          src={image.location}
                          alt={product.name}
                          fluid
                          className="rounded"
                        />
                      </div>
                    </Carousel.Item>
                  ))}
              </Carousel>
            </Col>
            <Col className="product-details col-lg-6 col-12 d-flex flex-column justify-content-between">
              <h4>{product.name}</h4>
              <h4 className="p-3 bg-light m-0 rounded-1">₱ {product.price}</h4>
              <h6>Product Descriptions</h6>
              <p className="text-muted">{product.description}</p>
              <h6>Product Specifications</h6>
              <p>Brand: {product.brand}</p>
              <p>Manufacturer Address: {product.manufacturerAddress}</p>
              <p>Stocks Available: {product.stocks}</p>

              <div className="d-flex justify-content-md-end justify-content-center align-items-center">
                <p className="me-3 m-0">Quantity</p>
                <InputGroup size="sm" className="quantity-input me-3">
                  <Button variant="light" onClick={() => updateQuantity(-1)}>
                    -
                  </Button>
                  <FormControl
                    className="text-center border-0"
                    type="number"
                    min="1"
                    value={quantity}
                    onChange={(e) =>
                      setQuantity(Math.max(1, parseInt(e.target.value)))
                    }
                  />
                  <Button variant="light" onClick={() => updateQuantity(1)}>
                    +
                  </Button>
                </InputGroup>

                <Button variant="light" onClick={handleAddToCart}>
                  <Cart2 className="me-2" />
                  Add To Cart
                </Button>
              </div>
            </Col>
          </Row>
        ) : (
          <Row>
            <Col className="text-center my-5">
              <h3>Fetching Product...</h3>
            </Col>
          </Row>
        )}
      </Container>
    </>
  );
}
