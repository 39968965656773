import React, { useContext, useState } from "react";
import { Modal, Form, Button } from "react-bootstrap";
import { XLg } from "react-bootstrap-icons";
import { AuthContext } from "../../contexts/AuthContext";
import { editUserProfile } from "../../services/userServices";
import { showErrorAlert, showSuccessAlert } from "../common/Alert";
import { UserUpdateContext } from "../../contexts/UserUpdateContext";
import { Eye, EyeSlash } from "react-bootstrap-icons";

export default function ProfileEditModals({
  showEditProfileModal,
  handleCloseEditProfileModal,
  showEditPasswordModal,
  handleCloseEditPasswordModal,
}) {
  const { handleForceUpdate } = useContext(UserUpdateContext);
  const { currentUser, setCurrentUser } = useContext(AuthContext);
  const [formValues, setFormValues] = useState(currentUser);
  const [showPassword, setShowPassword] = useState(false);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleCancel = () => {
    setFormValues({ ...currentUser });
    handleCloseEditPasswordModal();
    handleCloseEditProfileModal();
  };

  const handleEditProfileSubmit = async (event) => {
    event.preventDefault();

    const updatedUserFromClient = {
      firstName: formValues.firstName,
      lastName: formValues.lastName,
      mobileNo: formValues.mobileNo,
      address: formValues.address,
      email: formValues.email,
    };

    try {
      const { message, updatedUser } = await editUserProfile(
        formValues._id,
        updatedUserFromClient
      );
      setCurrentUser({
        ...updatedUser,
        oldPassword: "",
        newPassword: "",
        confirmNewPassword: "",
      });

      handleCloseEditProfileModal();
      showSuccessAlert(message);
      handleForceUpdate(updatedUser._id);
    } catch (error) {
      showErrorAlert(error);
    }
  };

  const handleEditPasswordSubmit = async (event) => {
    event.preventDefault();

    if (
      formValues.newPassword &&
      formValues.newPassword !== formValues.confirmNewPassword
    ) {
      showErrorAlert("Passwords do not match!");
      return;
    }

    const passwordUpdate = {
      oldPassword: formValues.oldPassword,
      newPassword: formValues.newPassword,
    };

    try {
      const { message, updatedUser } = await editUserProfile(
        formValues._id,
        passwordUpdate
      );
      setCurrentUser({
        ...updatedUser,
        oldPassword: "",
        newPassword: "",
        confirmNewPassword: "",
      });

      handleCloseEditPasswordModal();
      handleForceUpdate(updatedUser._id);
      showSuccessAlert(message);
    } catch (error) {
      showErrorAlert(error);
    }
  };

  return (
    <>
      <Modal
        show={showEditProfileModal}
        onHide={handleCloseEditProfileModal}
        size="md"
        centered
      >
        <Modal.Header>
          <div>
            <Modal.Title>Edit Profile</Modal.Title>
            <p className="m-0 text-muted" style={{ fontSize: "0.8rem" }}>
              Select the option you would like to edit:
            </p>
          </div>
          <Button
            variant="white"
            onClick={handleCancel}
            className="fw-semibold text-danger border-0 btn-outline-light"
          >
            <XLg /> Cancel
          </Button>
        </Modal.Header>
        <Modal.Body>
          <Form
            onSubmit={handleEditProfileSubmit}
            className="d-flex flex-column justify-content-evenly px-md-4"
          >
            <Form.Floating className="mb-1">
              <Form.Control
                className="border-0 border-bottom rounded-0 bg-white ps-md-4"
                type="text"
                placeholder="First Name"
                autoFocus
                name="firstName"
                value={formValues.firstName}
                onChange={handleInputChange}
              />
              <Form.Label>First Name</Form.Label>
            </Form.Floating>

            <Form.Floating className="my-1">
              <Form.Control
                className="border-0 border-bottom rounded-0 bg-white ps-md-4"
                type="text"
                placeholder="Last Name"
                name="lastName"
                value={formValues.lastName}
                onChange={handleInputChange}
              />
              <Form.Label>Last Name</Form.Label>
            </Form.Floating>

            <Form.Floating className="my-1">
              <Form.Control
                className="border-0 border-bottom rounded-0 bg-white ps-md-4"
                type="text"
                placeholder="Mobile Number"
                name="mobileNo"
                value={formValues.mobileNo}
                onChange={handleInputChange}
              />
              <Form.Label>Mobile Number</Form.Label>
            </Form.Floating>

            <Form.Floating className="my-1">
              <Form.Control
                className="border-0 border-bottom rounded-0 bg-white ps-md-4"
                type="text"
                placeholder="Address"
                name="address"
                value={formValues.address}
                onChange={handleInputChange}
              />
              <Form.Label>Address</Form.Label>
            </Form.Floating>

            <Form.Floating className="my-1">
              <Form.Control
                className="border-0 border-bottom rounded-0 bg-white ps-md-4"
                type="email"
                placeholder="Email Address"
                name="email"
                value={formValues.email}
                onChange={handleInputChange}
              />
              <Form.Label>Email address</Form.Label>
            </Form.Floating>

            <Button
              variant="white"
              type="submit"
              className="fw-semibold text-success border-0 mt-md-4 my-2 btn-outline-light"
            >
              Save
            </Button>
          </Form>
        </Modal.Body>
      </Modal>

      <Modal
        show={showEditPasswordModal}
        onHide={handleCloseEditPasswordModal}
        size="md"
        centered
      >
        <Modal.Header>
          <Modal.Title>Change Password</Modal.Title>
          <Button
            variant="white"
            onClick={handleCancel}
            className="fw-semibold text-danger border-0 btn-outline-light"
          >
            <XLg /> Cancel
          </Button>
        </Modal.Header>
        <Modal.Body>
          <Form
            onSubmit={handleEditPasswordSubmit}
            className="d-flex flex-column justify-content-evenly px-md-4"
          >
            <Form.Floating className="my-1">
              <Form.Control
                className="border-0 border-bottom rounded-0 bg-white ps-md-4"
                type={showPassword ? "password" : "text"}
                placeholder="Old Password"
                autoFocus
                name="oldPassword"
                value={formValues.oldPassword}
                onChange={handleInputChange}
                required
              />
              <Form.Label>Old Password</Form.Label>
              <div
                onClick={togglePasswordVisibility}
                className="position-absolute top-50 end-0 me-3 translate-middle-y border-0"
                style={{ cursor: "pointer", zIndex: 2, fontSize: "1.5rem" }}
              >
                {showPassword ? (
                  <EyeSlash className="text-secondary fw-bolder" />
                ) : (
                  <Eye className="text-dark fw-bolder" />
                )}
              </div>
            </Form.Floating>

            <Form.Floating className="my-1">
              <Form.Control
                className="border-0 border-bottom rounded-0 bg-white ps-md-4"
                type={showPassword ? "password" : "text"}
                placeholder="New Password"
                name="newPassword"
                value={formValues.newPassword}
                onChange={handleInputChange}
                required
              />
              <Form.Label>New Password</Form.Label>
              <div
                onClick={togglePasswordVisibility}
                className="position-absolute top-50 end-0 me-3 translate-middle-y border-0"
                style={{ cursor: "pointer", zIndex: 2, fontSize: "1.5rem" }}
              >
                {showPassword ? (
                  <EyeSlash className="text-secondary fw-bolder" />
                ) : (
                  <Eye className="text-dark fw-bolder" />
                )}
              </div>
            </Form.Floating>

            <Form.Floating className="my-1">
              <Form.Control
                className="border-0 border-bottom rounded-0 bg-white ps-md-4"
                type={showPassword ? "password" : "text"}
                placeholder="Confirm New Password"
                name="confirmNewPassword"
                value={formValues.confirmNewPassword}
                onChange={handleInputChange}
                required
              />
              <Form.Label>Confirm New Password</Form.Label>
              <div
                onClick={togglePasswordVisibility}
                className="position-absolute top-50 end-0 me-3 translate-middle-y border-0"
                style={{ cursor: "pointer", zIndex: 2, fontSize: "1.5rem" }}
              >
                {showPassword ? (
                  <EyeSlash className="text-secondary fw-bolder" />
                ) : (
                  <Eye className="text-dark fw-bolder" />
                )}
              </div>
            </Form.Floating>
            <Button
              variant="white"
              type="submit"
              className="fw-semibold text-success border-0 mt-md-4 mt-2 btn-outline-light"
            >
              Save
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
}
