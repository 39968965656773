import React from "react";
import { Container, Row, Col, Nav } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Facebook, Twitter, Instagram, Youtube } from "react-bootstrap-icons";

export default function Footer() {
  return (
    <footer className="py-4 border-top shadow-sm bg-dark text-white">
      <Container className="p-md-5">
        <Row>
          <Col md={4}>
            <h5>About Us</h5>
            <p>
              We are a leading ecommerce platform providing a wide range of
              products to our customers.
            </p>
          </Col>
          <Col md={4}>
            <h5>Contact Us</h5>
            <address>
              123 Main St
              <br />
              Anytown, USA 12345
              <br />
              Phone: (123) 456-7890
              <br />
              Email: info@example.com
            </address>
          </Col>
          <Col md={4}>
            <h5>Follow Us</h5>
            <ul className="list-unstyled">
              <li>
                <Nav.Link as={Link} to="/">
                  <Facebook />
                </Nav.Link>
              </li>
              <li>
                <Nav.Link as={Link} to="/">
                  <Twitter />
                </Nav.Link>
              </li>
              <li>
                <Nav.Link as={Link} to="/">
                  <Instagram />
                </Nav.Link>
              </li>
              <li>
                <Nav.Link as={Link} to="/">
                  <Youtube />
                </Nav.Link>
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
    </footer>
  );
}
