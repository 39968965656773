import React, { useContext, useEffect, useState } from "react";
import { Button, Container, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import { showErrorAlert } from "../common/Alert";
import {
  Search,
  PlusLg,
  ArchiveFill,
  Pencil,
  Image,
  PlusSlashMinus,
} from "react-bootstrap-icons";
import ProductCreationModal from "./ProductCreationModal";
import SearchBar from "../common/SearchBar";
import { ProductContext } from "../../contexts/ProductContext";
import ProductUpdateModal from "./ProductUpdateModal";
import ProductImageModal from "./ProductImageModal";
import { updateProductDetails } from "../../services/productsServices";

//Todo: put the latest unarchive as new and newly created and updated product at the top
export default function ProductsActive() {
  const {
    activeProductList,
    triggerUpdate,
    setTriggerUpdate,
    searchKeyword,
    setSearchKeyword,
    setActiveProductList,
    setInactiveProductList,
  } = useContext(ProductContext);
  const [product, setProduct] = useState({});
  const [searchResults, setSearchResults] = useState(activeProductList);

  const [showAddProductModal, setShowAddProductModal] = useState(false);
  const handleCloseAddProductModal = () => setShowAddProductModal(false);
  const handleShowAddProductModal = () => setShowAddProductModal(true);

  const [currentProduct, setCurrentProduct] = useState(null);
  const [showEditProductModal, setShowEditProductModal] = useState(false);
  const handleCloseEditProductModal = () => setShowEditProductModal(false);
  const handleShowEditProductModal = (product) => {
    setCurrentProduct(product);
    setShowEditProductModal(true);
  };

  const [showImageModal, setShowImageModal] = useState(false);
  const handleShowImageModal = (product) => {
    setProduct(product);
    setShowImageModal(true);
  };
  const handleCloseImageModal = () => setShowImageModal(false);

  useEffect(() => {
    if (searchKeyword === "") {
      setSearchResults(activeProductList);
    } else {
      const filteredProducts = activeProductList.filter((product) => {
        const regex = new RegExp(searchKeyword, "i");
        return (
          regex.test(product.name) ||
          regex.test(product.brand) ||
          regex.test(product.description)
        );
      });
      setSearchResults(filteredProducts);
    }
  }, [searchKeyword, activeProductList, setSearchResults]);

  const handleProductSearch = (searchValue) => {
    setSearchKeyword(searchValue);
  };

  const handleArchive = async (productId) => {
    try {
      await updateProductDetails(productId, { isActive: false });
      const product = activeProductList.find((p) => p._id === productId);
      setActiveProductList((prevProductList) =>
        prevProductList.filter((product) => product._id !== productId)
      );
      setInactiveProductList((prevProductList) => [
        ...prevProductList,
        { ...product, isActive: false },
      ]);
      setTriggerUpdate(!triggerUpdate);
    } catch (error) {
      showErrorAlert(error);
    }
  };

  const sortedProductList = searchResults.sort(
    (a, b) => new Date(b.createdOn) - new Date(a.createdOn)
  );

  return (
    <Container className="p-md-3 bg-white">
      <p style={{ fontSize: "0.9rem" }} className="fw-semibold">
        Products: {sortedProductList.length}
      </p>
      <div className="d-flex align-items-center">
        <SearchBar
          customStyle={"w-100 me-2"}
          onSearch={handleProductSearch}
          placeholder={"Search Keyword: Products name, brand or description."}
        />
        <Button
          type="submit"
          variant="primary"
          className="rounded"
          onClick={handleShowAddProductModal}
        >
          <PlusLg className="h5 m-0" />
        </Button>
        <ProductCreationModal
          showAddProductModal={showAddProductModal}
          handleCloseAddProductModal={handleCloseAddProductModal}
        />
      </div>

      <div className="css-scroll my-3 border rounded">
        <Table hover responsive>
          <thead className="text-center">
            <tr>
              <th>News</th>
              <th>ProductId</th>
              <th>ProductName</th>
              <th>Archive</th>
              <th>Edit</th>
              <th>
                <PlusSlashMinus /> Image
              </th>
              <th>View</th>
            </tr>
          </thead>
          <tbody className="text-center css-scroll">
            {sortedProductList.map((product) => (
              <tr key={product._id}>
                <td>
                  {product.isUpdated && (
                    <h6 className="m-0 badge bg-success my-2 py-1 text-white small">
                      Updated
                    </h6>
                  )}
                </td>
                <td>{product._id}</td>
                <td>{product.name}</td>
                <td>
                  <Button
                    variant="light"
                    className="border-0 rounded-circle shadow-sm"
                    onClick={() => handleArchive(product._id)}
                  >
                    <ArchiveFill />
                  </Button>
                </td>
                <td>
                  <Button
                    variant="light"
                    className="border-0 rounded-circle shadow-sm"
                    onClick={() => handleShowEditProductModal(product)}
                  >
                    <Pencil />
                  </Button>
                </td>
                <td>
                  <Button
                    variant="light"
                    className="border-0 rounded-circle shadow-sm"
                    onClick={() => handleShowImageModal(product)}
                  >
                    <Image />
                  </Button>
                </td>
                <td>
                  <Button
                    as={Link}
                    to={`/products/${product._id}`}
                    variant="light"
                    className="border-0 rounded-circle shadow-sm"
                  >
                    <Search />
                  </Button>
                </td>
              </tr>
            ))}
            {sortedProductList.length === 0 && (
              <tr>
                <td colSpan="6" className="text-center">
                  No Active products found.
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>
      <ProductUpdateModal
        showEditProductModal={showEditProductModal}
        handleCloseEditProductModal={handleCloseEditProductModal}
        currentProduct={currentProduct}
      />
      <ProductImageModal
        product={product}
        showImageModal={showImageModal}
        handleCloseImageModal={handleCloseImageModal}
      />
    </Container>
  );
}
