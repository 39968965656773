import React, { useContext, useEffect, useState } from "react";
import { Modal, Form, Button } from "react-bootstrap";
import { XLg } from "react-bootstrap-icons";
import { editUserProfile, getUserById } from "../../services/userServices";
import { showErrorAlert, showSuccessAlert } from "../common/Alert";
import { UserUpdateContext } from "../../contexts/UserUpdateContext";

export default function ProfilesListEditModals({
  selectedUser,
  showEditUserModal,
  setShowEditUserModal,
}) {
  const { forceUpdate, handleForceUpdate } = useContext(UserUpdateContext);
  const [currentSelectedUser, setCurrentSelectedUser] = useState({
    firstName: "",
    lastName: "",
    mobileNo: "",
    address: "",
    email: "",
  });

  useEffect(() => {
    const fetchUserProfile = async () => {
      if (selectedUser) {
        const { userProfile } = await getUserById(selectedUser._id);
        setCurrentSelectedUser(userProfile);
      }
    };

    fetchUserProfile();
  }, [selectedUser, setCurrentSelectedUser, forceUpdate]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setCurrentSelectedUser({ ...currentSelectedUser, [name]: value });
  };

  const handleCancel = () => {
    setCurrentSelectedUser({ ...selectedUser });
    setShowEditUserModal(false);
  };

  const handleEditProfileSubmit = async (event) => {
    event.preventDefault();

    const updatedUserFromClient = {
      firstName: currentSelectedUser.firstName,
      lastName: currentSelectedUser.lastName,
      mobileNo: currentSelectedUser.mobileNo,
      address: currentSelectedUser.address,
      email: currentSelectedUser.email,
    };

    try {
      const { message, updatedUser } = await editUserProfile(
        currentSelectedUser._id,
        updatedUserFromClient
      );
      setCurrentSelectedUser(updatedUser);
      setShowEditUserModal(false);
      showSuccessAlert(message);
      handleForceUpdate(updatedUser._id);
    } catch (error) {
      showErrorAlert(error);
    }
  };

  return (
    <>
      <Modal
        show={showEditUserModal}
        onHide={() => setShowEditUserModal(false)}
        size="md"
        centered
      >
        <Modal.Header>
          <div>
            <Modal.Title>
              {currentSelectedUser.isAdmin
                ? "Your Highness - sama!"
                : `User ${currentSelectedUser.firstName} - sama`}
            </Modal.Title>
            <p className="m-0 text-muted" style={{ fontSize: "0.8rem" }}>
              Select the option you would like to edit:
            </p>
          </div>
          <Button
            variant="outline-danger"
            onClick={handleCancel}
            className="fw-semibold border-0"
          >
            <XLg /> Cancel
          </Button>
        </Modal.Header>
        <Modal.Body>
          <Form
            onSubmit={handleEditProfileSubmit}
            className="d-flex flex-column justify-content-evenly px-md-4"
          >
            <Form.Floating className="mb-1">
              <Form.Control
                className="border-0 border-bottom rounded-0 bg-white ps-md-4"
                type="text"
                placeholder="First Name"
                autoFocus
                name="firstName"
                value={currentSelectedUser.firstName}
                onChange={handleInputChange}
              />
              <Form.Label>First Name</Form.Label>
            </Form.Floating>

            <Form.Floating className="my-1">
              <Form.Control
                className="border-0 border-bottom rounded-0 bg-white ps-md-4"
                type="text"
                placeholder="Last Name"
                name="lastName"
                value={currentSelectedUser.lastName}
                onChange={handleInputChange}
              />
              <Form.Label>Last Name</Form.Label>
            </Form.Floating>

            <Form.Floating className="my-1">
              <Form.Control
                className="border-0 border-bottom rounded-0 bg-white ps-md-4"
                type="text"
                placeholder="Mobile Number"
                name="mobileNo"
                value={currentSelectedUser.mobileNo}
                onChange={handleInputChange}
              />
              <Form.Label>Mobile Number</Form.Label>
            </Form.Floating>

            <Form.Floating className="my-1">
              <Form.Control
                className="border-0 border-bottom rounded-0 bg-white ps-md-4"
                type="text"
                placeholder="Address"
                name="address"
                value={currentSelectedUser.address}
                onChange={handleInputChange}
              />
              <Form.Label>Address</Form.Label>
            </Form.Floating>

            <Form.Floating className="my-1">
              <Form.Control
                className="border-0 border-bottom rounded-0 bg-white ps-md-4"
                type="email"
                placeholder="Email Address"
                name="email"
                value={currentSelectedUser.email}
                onChange={handleInputChange}
              />
              <Form.Label>Email address</Form.Label>
            </Form.Floating>

            <Button
              variant="outline-success"
              type="submit"
              className="fw-semibold border-0 mt-md-4 my-2"
            >
              Save
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
}
