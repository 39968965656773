import axios from "axios";
import * as config from "../config";

// fetch sign up from api
export const registerUser = async (userData) => {
  try {
    const response = await axios.post(
      `${config.apiBaseUrl}/api/auth/signup`,
      userData,
      { withCredentials: true }
    );
    return response.data;
  } catch (error) {
    console.error("error", error);
    console.error("error.response", error.response);
    console.error("error.response.data", error.response.data);
    if (error.response.data.hasOwnProperty("details")) {
      const errorMessage = `${error.response.data.message} ${error.response.data.details}`;
      throw new Error(errorMessage || "Unable to register");
    } else {
      throw new Error(error.response.data.message || "Unable to register");
    }
  }
};

// fetch login from api
export const loginUser = async (userData, setCookie) => {
  try {
    const response = await axios.post(
      `${config.apiBaseUrl}/api/auth/login`,
      userData,
      { withCredentials: true }
    );
    setCookie("CURRENT_USER", response.data.profile._id, { path: "/" });
    return response.data;
  } catch (error) {
    console.error("error", error);
    console.error("error.response", error.response);
    console.error("error.response.data", error.response.data);
    if (
      error.response.data.message === "Email does not exist." &&
      error.response.status === 404
    ) {
      throw new Error("Email does not exist. Please sign up first.");
    } else if (error.response.data.hasOwnProperty("details")) {
      const errorMessage = `${error.response.data.message} ${error.response.data.details}`;
      throw new Error(errorMessage || "Unable to login");
    } else {
      throw new Error(error.response.data.message || "Unable to login");
    }
  }
};

// fetch user by id from api
export const getUserById = async (userId) => {
  try {
    const response = await axios.get(
      `${config.apiBaseUrl}/api/users/${userId}`,
      { withCredentials: true }
    );
    return response.data;
  } catch (error) {
    console.error("error", error);
    console.error("error.response", error.response);
    console.error("error.response.data", error.response.data);
    if (error.response.data.hasOwnProperty("details")) {
      const errorMessage = `${error.response.data.message} ${error.response.data.details}`;
      throw new Error(errorMessage || "Unable to get user data");
    } else {
      throw new Error(error.response.data.message || "Unable to get user data");
    }
  }
};

// fetch all users from api
export const getAllUsers = async (keyword) => {
  try {
    const response = await axios.get(`${config.apiBaseUrl}/api/users`, {
      withCredentials: true,
      keyword,
    });
    return response.data;
  } catch (error) {
    console.error("error", error);
    console.error("error.response", error.response);
    console.error("error.response.data", error.response.data);
    if (error.response.data.hasOwnProperty("details")) {
      const errorMessage = `${error.response.data.message} ${error.response.data.details}`;
      throw new Error(errorMessage || "Unable to get all users data");
    } else {
      throw new Error(
        error.response.data.message || "Unable to get all users data"
      );
    }
  }
};

// edit users profile patch from api
export const editUserProfile = async (userId, updatedUser) => {
  try {
    const response = await axios.patch(
      `${config.apiBaseUrl}/api/users/${userId}`,
      updatedUser,
      { withCredentials: true }
    );
    return response.data;
  } catch (error) {
    console.error("error", error);
    console.error("error.response", error.response);
    console.error("error.response.data", error.response.data);
    if (error.response.data.hasOwnProperty("details")) {
      const errorMessage = `${error.response.data.message} ${error.response.data.details}`;
      throw new Error(errorMessage || "Unable to edit user data");
    } else {
      throw new Error(
        error.response.data.message || "Unable to edit user data"
      );
    }
  }
};

// remove a user delete from api
export const deleteUserProfile = async (userId) => {
  try {
    const response = await axios.delete(
      `${config.apiBaseUrl}/api/users/${userId}`,
      { withCredentials: true }
    );
    return response.data;
  } catch (error) {
    console.error("error", error);
    console.error("error.response", error.response);
    console.error("error.response.data", error.response.data);
    if (error.response.data.hasOwnProperty("details")) {
      const errorMessage = `${error.response.data.message} ${error.response.data.details}`;
      throw new Error(errorMessage || "Unable to delete user data");
    } else {
      throw new Error(
        error.response.data.message || "Unable to delete user data"
      );
    }
  }
};

// logout from api
export const logoutUser = async () => {
  try {
    const response = await axios.post(`${config.apiBaseUrl}/api/auth/logout`, {
      withCredentials: true,
    });
    return response.data;
  } catch (error) {
    console.error("error", error);
    console.error("error.response", error.response);
    console.error("error.response.data", error.response.data);
    if (error.response.data.hasOwnProperty("details")) {
      const errorMessage = `${error.response.data.message} ${error.response.data.details}`;
      throw new Error(errorMessage || "Unable to logout");
    } else {
      throw new Error(error.response.data.message || "Unable to logout");
    }
  }
};

// add image to user
export const addUpdateUserImage = async (userId, formdata) => {
  try {
    const response = await axios.post(
      `${config.apiBaseUrl}/api/users/${userId}/images`,
      formdata,
      { withCredentials: true }
    );
    return response.data;
  } catch (error) {
    console.error("error", error);
    console.error("error.response", error.response);
    console.error("error.response.data", error.response.data);
    if (error.response.data.hasOwnProperty("details")) {
      const errorMessage = `${error.response.data.message} ${error.response.data.details}`;
      throw new Error(errorMessage || "Unable to add image");
    } else {
      throw new Error(error.response.data.message || "Unable to add image");
    }
  }
};
