import React, { useContext, useState } from "react";
import { Col, Container, Image, Nav, Row, Tab, Tabs } from "react-bootstrap";
import { Link } from "react-router-dom";
import { AuthContext } from "../../contexts/AuthContext";
import { updateOrder } from "../../services/orderServices";
import { showWarningAlert } from "../common/Alert";
import { OrderContext } from "../../contexts/OrderContext";
import { showErrorToast, showSuccessToast } from "../common/ToastAlert";

export default function OrderList({ customStyle }) {
  const { isAuthenticated, currentUser } = useContext(AuthContext);
  const { orderDetails, UpdateCurrentUserOrders } = useContext(OrderContext);
  const [key, setKey] = useState("pending");
  const isAdministrator = currentUser.isAdmin;
  const style = customStyle ? customStyle : "my-md-5 my-3";

  const handleStatusConfirmation = (orderId, newStatus) => {
    const title = "Are you sure you want to CANCEL this order?";
    const message = "You won't be able to revert it back.";
    showWarningAlert(title, message, () =>
      handleUpdateStatus(orderId, newStatus)
    );
  };

  const handleUpdateStatus = async (orderId, newStatus) => {
    try {
      if (isAuthenticated) {
        const data = {
          orderId: orderId,
          newStatus,
        };

        const { message, updatedOrder } = await updateOrder(data);

        UpdateCurrentUserOrders(orderId, updatedOrder);
        showSuccessToast(message);
      }
    } catch (error) {
      showErrorToast(error.message || error);
    }
  };

  const displayStatusCount = (status) => {
    const statusCount = {
      PENDING: 0,
      PROCESSED: 0,
      SHIPPED: 0,
      DELIVERED: 0,
      CANCELLED: 0,
    };

    orderDetails.forEach((order) => {
      if (order !== null && order.status in statusCount) {
        statusCount[order.status]++;
      }
    });

    return statusCount[status];
  };

  return (
    <div className={`${style}`}>
      <div className="border rounded bg-white">
        {isAdministrator ? (
          <div className="css-scroll text-muted text-center p-md-5">
            Admin's Do not have this feature.
          </div>
        ) : (
          <Tabs
            id="controlled-tab-example"
            activeKey={key}
            onSelect={(k) => setKey(k)}
            justify
          >
            <Tab eventKey="pending" title="Pending">
              <div>
                <Col className="text-muted ps-md-5 ps-1">
                  Pending: {displayStatusCount("PENDING")}
                </Col>
                <div className="css-scroll border-top border-1 border-secondary">
                  {orderDetails
                    .filter(
                      (order) => order !== null && order.status === "PENDING"
                    )
                    .map((order) => (
                      <Container
                        key={order._id}
                        className="border-bottom border-1 border-secondary px-md-5 p-1 shadow py-md-3"
                      >
                        {order.purchasedProducts.map((item) => (
                          <Row key={item._id} className="m-0">
                            <Col className="col-12 d-flex justify-content-between p-0">
                              <p className="fw-semibold m-0">
                                {item.productBrand}
                              </p>
                            </Col>
                            <hr className="m-0" />
                            <Col className="col-12 d-flex justify-content-between align-items-center p-0 my-2">
                              <Col className="d-flex col-md-10">
                                <div className="col-md-2 col-6 d-flex flex-fill">
                                  <Image
                                    src={item.imageUrl}
                                    fluid
                                    className="border border-1"
                                  />
                                </div>
                                <div className="ps-2 col-md-10 col-6">
                                  <p className="m-0">{item.productName}</p>
                                  <p
                                    className="m-0 text-muted"
                                    style={{ fontSize: "0.8rem" }}
                                  >
                                    From: {item.manufacturerAddress}
                                  </p>
                                  <p className="m-0">x{item.quantity}</p>
                                </div>
                              </Col>
                              <div className="col-md-2 d-flex flex-column justify-content-between h-100 align-items-end">
                                <p className="m-0">₱ {item.totalPrice}</p>
                                <Nav.Link
                                  as={Link}
                                  to={`/products/${item.productId}`}
                                  className="px-2 py-1 bg-success text-white rounded"
                                >
                                  Buy Again
                                </Nav.Link>
                              </div>
                            </Col>
                          </Row>
                        ))}
                        <Col className="bg-light d-flex flex-wrap justify-content-between align-items-center p-2">
                          <div className="col-md-4 col-12 d-flex justify-content-md-start justify-content-center">
                            <h6 className="text-secondary m-0">
                              {order.status}
                            </h6>
                          </div>
                          <div className="col-md-4 col-12">
                            {order.status === "PENDING" && (
                              <Nav.Link
                                className="px-3 py-1 bg-danger text-white text-center rounded"
                                onClick={() =>
                                  handleStatusConfirmation(
                                    order._id,
                                    "CANCELLED"
                                  )
                                }
                              >
                                CANCEL
                              </Nav.Link>
                            )}
                          </div>
                          <div className="col-md-4 col-12 d-flex justify-content-md-end justify-content-center">
                            <p className="m-0">{`Total (${
                              order.purchasedProducts.length
                            } ${
                              order.purchasedProducts.length <= 1
                                ? "item"
                                : "items"
                            }):`}</p>
                            <h5 className="m-0 ms-2">
                              ₱{order.totalPurchasedAmount}
                            </h5>
                          </div>
                        </Col>
                      </Container>
                    ))}
                  {displayStatusCount("PENDING") === 0 && (
                    <div className="text-muted text-center p-md-5">
                      There are no pending orders.
                    </div>
                  )}
                </div>
              </div>
            </Tab>
            <Tab eventKey="processed" title="Processed">
              <div>
                <Col className="text-muted ps-md-5 ps-1">
                  Processed: {displayStatusCount("PROCESSED")}
                </Col>
                <div className="css-scroll border-top border-1 border-info">
                  {orderDetails
                    .filter(
                      (order) => order !== null && order.status === "PROCESSED"
                    )
                    .map((order) => (
                      <Container
                        key={order._id}
                        className="border-bottom border-1 border-info px-md-5 p-1 shadow py-md-3"
                      >
                        {order.purchasedProducts.map((item) => (
                          <Row key={item._id} className="m-0">
                            <Col className="col-12 d-flex justify-content-between p-0">
                              <p className="fw-semibold m-0">
                                {item.productBrand}
                              </p>
                            </Col>
                            <hr className="m-0" />
                            <Col className="col-12 d-flex justify-content-between align-items-center p-0 my-2">
                              <Col className="d-flex col-md-10">
                                <div className="col-md-2 col-6 d-flex flex-fill">
                                  <Image
                                    src={item.imageUrl}
                                    fluid
                                    className="border border-1"
                                  />
                                </div>
                                <div className="ps-2 col-md-10 col-6">
                                  <p className="m-0">{item.productName}</p>
                                  <p
                                    className="m-0 text-muted"
                                    style={{ fontSize: "0.8rem" }}
                                  >
                                    From: {item.manufacturerAddress}
                                  </p>
                                  <p className="m-0">x{item.quantity}</p>
                                </div>
                              </Col>
                              <div className="col-md-2 d-flex flex-column justify-content-between h-100 align-items-end">
                                <p className="m-0">₱ {item.totalPrice}</p>
                                <Nav.Link
                                  as={Link}
                                  to={`/products/${item.productId}`}
                                  className="px-2 py-1 bg-success text-white rounded"
                                >
                                  Buy Again
                                </Nav.Link>
                              </div>
                            </Col>
                          </Row>
                        ))}
                        <Col className="bg-light d-flex flex-wrap justify-content-between align-items-center p-2">
                          <div className="col-md-4 col-12 d-flex justify-content-md-start justify-content-center">
                            <h6 className="text-info m-0">{order.status}</h6>
                          </div>
                          <div className="col-md-4 col-12 d-flex justify-content-md-end justify-content-center">
                            <p className="m-0">{`Total (${
                              order.purchasedProducts.length
                            } ${
                              order.purchasedProducts.length <= 1
                                ? "item"
                                : "items"
                            }):`}</p>
                            <h5 className="m-0 ms-2">
                              ₱{order.totalPurchasedAmount}
                            </h5>
                          </div>
                        </Col>
                      </Container>
                    ))}
                  {displayStatusCount("PROCESSED") === 0 && (
                    <div className="text-muted text-center p-md-5">
                      There are no orders currently being processed.
                    </div>
                  )}
                </div>
              </div>
            </Tab>
            <Tab eventKey="shipped" title="Shipped">
              <div>
                <Col className="text-muted ps-md-5 ps-1">
                  Shipped: {displayStatusCount("SHIPPED")}
                </Col>
                <div className="css-scroll border-top border-1 border-primary">
                  {orderDetails
                    .filter(
                      (order) => order !== null && order.status === "SHIPPED"
                    )
                    .map((order) => (
                      <Container
                        key={order._id}
                        className="border-bottom border-1 border-primary px-md-5 p-1 shadow py-md-3"
                      >
                        {order.purchasedProducts.map((item) => (
                          <Row key={item._id} className="m-0">
                            <Col className="col-12 d-flex justify-content-between p-0">
                              <p className="fw-semibold m-0">
                                {item.productBrand}
                              </p>
                            </Col>
                            <hr className="m-0" />
                            <Col className="col-12 d-flex justify-content-between align-items-center p-0 my-2">
                              <Col className="d-flex col-md-10">
                                <div className="col-md-2 col-6 d-flex flex-fill">
                                  <Image
                                    src={item.imageUrl}
                                    fluid
                                    className="border border-1"
                                  />
                                </div>
                                <div className="ps-2 col-md-10 col-6">
                                  <p className="m-0">{item.productName}</p>
                                  <p
                                    className="m-0 text-muted"
                                    style={{ fontSize: "0.8rem" }}
                                  >
                                    From: {item.manufacturerAddress}
                                  </p>
                                  <p className="m-0">x{item.quantity}</p>
                                </div>
                              </Col>
                              <div className="col-md-2 d-flex flex-column justify-content-between h-100 align-items-end">
                                <p className="m-0">₱ {item.totalPrice}</p>
                                <Nav.Link
                                  as={Link}
                                  to={`/products/${item.productId}`}
                                  className="px-2 py-1 bg-success text-white rounded"
                                >
                                  Buy Again
                                </Nav.Link>
                              </div>
                            </Col>
                          </Row>
                        ))}
                        <Col className="bg-light d-flex flex-wrap justify-content-between align-items-center p-2">
                          <div className="col-md-4 col-12 d-flex justify-content-md-start justify-content-center">
                            <h6 className="text-primary m-0">{order.status}</h6>
                          </div>
                          <div className="col-md-4 col-12 d-flex justify-content-md-end justify-content-center">
                            <p className="m-0">{`Total (${
                              order.purchasedProducts.length
                            } ${
                              order.purchasedProducts.length <= 1
                                ? "item"
                                : "items"
                            }):`}</p>
                            <h5 className="m-0 ms-2">
                              ₱{order.totalPurchasedAmount}
                            </h5>
                          </div>
                        </Col>
                      </Container>
                    ))}
                  {displayStatusCount("SHIPPED") === 0 && (
                    <div className="text-muted text-center p-md-5">
                      There are no orders currently being Shipped.
                    </div>
                  )}
                </div>
              </div>
            </Tab>
            <Tab eventKey="delivered" title="Delivered">
              <div>
                <Col className="text-muted ps-md-5 ps-1">
                  Delivered: {displayStatusCount("DELIVERED")}
                </Col>
                <div className="css-scroll border-top border-1 border-success">
                  {orderDetails
                    .filter(
                      (order) => order !== null && order.status === "DELIVERED"
                    )
                    .map((order) => (
                      <Container
                        key={order._id}
                        className="border-bottom border-1 border-success px-md-5 p-1 shadow py-md-3"
                      >
                        {order.purchasedProducts.map((item) => (
                          <Row key={item._id} className="m-0">
                            <Col className="col-12 d-flex justify-content-between p-0">
                              <p className="fw-semibold m-0">
                                {item.productBrand}
                              </p>
                            </Col>
                            <hr className="m-0" />
                            <Col className="col-12 d-flex justify-content-between align-items-center p-0 my-2">
                              <Col className="d-flex col-md-10">
                                <div className="col-md-2 col-6 d-flex flex-fill">
                                  <Image
                                    src={item.imageUrl}
                                    fluid
                                    className="border border-1"
                                  />
                                </div>
                                <div className="ps-2 col-md-10 col-6">
                                  <p className="m-0">{item.productName}</p>
                                  <p
                                    className="m-0 text-muted"
                                    style={{ fontSize: "0.8rem" }}
                                  >
                                    From: {item.manufacturerAddress}
                                  </p>
                                  <p className="m-0">x{item.quantity}</p>
                                </div>
                              </Col>
                              <div className="col-md-2 d-flex flex-column justify-content-between h-100 align-items-end">
                                <p className="m-0">₱ {item.totalPrice}</p>
                                <Nav.Link
                                  as={Link}
                                  to={`/products/${item.productId}`}
                                  className="px-2 py-1 bg-success text-white rounded"
                                >
                                  Buy Again
                                </Nav.Link>
                              </div>
                            </Col>
                          </Row>
                        ))}
                        <Col className="bg-light d-flex flex-wrap justify-content-between align-items-center p-2">
                          <div className="col-md-4 col-12 d-flex justify-content-md-start justify-content-center">
                            <h6 className="text-success m-0">{order.status}</h6>
                          </div>
                          <div className="col-md-4 col-12 d-flex justify-content-md-end justify-content-center">
                            <p className="m-0">{`Total (${
                              order.purchasedProducts.length
                            } ${
                              order.purchasedProducts.length <= 1
                                ? "item"
                                : "items"
                            }):`}</p>
                            <h5 className="m-0 ms-2">
                              ₱{order.totalPurchasedAmount}
                            </h5>
                          </div>
                        </Col>
                      </Container>
                    ))}
                  {displayStatusCount("SHIPPED") === 0 && (
                    <div className="text-muted text-center p-md-5">
                      There are no Delivered orders.
                    </div>
                  )}
                </div>
              </div>
            </Tab>
            <Tab eventKey="cancelled" title="Cancelled">
              <div>
                <Col className="text-muted ps-md-5 ps-1">
                  Cancelled: {displayStatusCount("CANCELLED")}
                </Col>
                <div className="css-scroll border-top border-1 border-danger">
                  {orderDetails
                    .filter(
                      (order) => order !== null && order.status === "CANCELLED"
                    )
                    .map((order) => (
                      <Container
                        key={order._id}
                        className="border-bottom border-1 border-danger px-md-5 p-1 shadow py-md-3"
                      >
                        {order.purchasedProducts.map((item) => (
                          <Row key={item._id} className="m-0">
                            <Col className="col-12 d-flex justify-content-between p-0">
                              <p className="fw-semibold m-0">
                                {item.productBrand}
                              </p>
                            </Col>
                            <hr className="m-0" />
                            <Col className="col-12 d-flex justify-content-between align-items-center p-0 my-2">
                              <Col className="d-flex col-md-10">
                                <div className="col-md-2 col-6 d-flex flex-fill">
                                  <Image
                                    src={item.imageUrl}
                                    fluid
                                    className="border border-1"
                                  />
                                </div>
                                <div className="ps-2 col-md-10 col-6">
                                  <p className="m-0">{item.productName}</p>
                                  <p
                                    className="m-0 text-muted"
                                    style={{ fontSize: "0.8rem" }}
                                  >
                                    From: {item.manufacturerAddress}
                                  </p>
                                  <p className="m-0">x{item.quantity}</p>
                                </div>
                              </Col>
                              <div className="col-md-2 d-flex flex-column justify-content-between h-100 align-items-end">
                                <p className="m-0">₱ {item.totalPrice}</p>
                                <Nav.Link
                                  as={Link}
                                  to={`/products/${item.productId}`}
                                  className="px-2 py-1 bg-success text-white rounded"
                                >
                                  Buy Again
                                </Nav.Link>
                              </div>
                            </Col>
                          </Row>
                        ))}
                        <Col className="bg-light d-flex flex-wrap justify-content-between align-items-center p-2">
                          <div className="col-md-4 col-12 d-flex justify-content-md-start justify-content-center">
                            <h6 className="text-danger m-0">{order.status}</h6>
                          </div>
                          <div className="col-md-4 col-12 d-flex justify-content-md-end justify-content-center">
                            <p className="m-0">{`Total (${
                              order.purchasedProducts.length
                            } ${
                              order.purchasedProducts.length <= 1
                                ? "item"
                                : "items"
                            }):`}</p>
                            <h5 className="m-0 ms-2">
                              ₱{order.totalPurchasedAmount}
                            </h5>
                          </div>
                        </Col>
                      </Container>
                    ))}
                  {displayStatusCount("CANCELLED") === 0 && (
                    <div className="text-muted text-center p-md-5">
                      There are no Cancelled orders.
                    </div>
                  )}
                </div>
              </div>
            </Tab>
          </Tabs>
        )}
      </div>
    </div>
  );
}
