import React, { useContext, useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { XLg } from "react-bootstrap-icons";
import {
  deleteProduct,
  updateProductDetails,
} from "../../services/productsServices";
import { ProductContext } from "../../contexts/ProductContext";
import { showErrorAlert, showSuccessAlert } from "../common/Alert";

export default function ProductUpdateModal({
  showEditProductModal,
  handleCloseEditProductModal,
  currentProduct,
}) {
  const { updateProduct } = useContext(ProductContext);
  const [product, setProduct] = useState({
    name: "",
    brand: "",
    description: "",
    manufacturerAddress: "",
    price: "",
    stocks: "",
  });

  const [previousProduct, setPreviousProduct] = useState({});

  useEffect(() => {
    if (currentProduct) {
      setProduct(currentProduct);
      setPreviousProduct(currentProduct);
    }
  }, [currentProduct]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setProduct({ ...product, [name]: value });
  };

  const handleCancel = () => {
    setProduct({ ...previousProduct });
    handleCloseEditProductModal();
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const { message, updatedProduct } = await updateProductDetails(
        product._id,
        product
      );
      setProduct({ ...updatedProduct });
      updateProduct(updatedProduct);
      showSuccessAlert(message);
      handleCloseEditProductModal();
    } catch (error) {
      showErrorAlert(error);
    }
  };

  const handleDelete = async () => {
    try {
      const { message } = await deleteProduct(product._id);
      showSuccessAlert(message);
      handleCloseEditProductModal();
      updateProduct(null);
    } catch (error) {
      showErrorAlert(error);
    }
  };

  return (
    <>
      <Modal
        show={showEditProductModal}
        onHide={handleCancel}
        size="md"
        centered
      >
        <Modal.Header>
          <Modal.Title>Edit Product</Modal.Title>
          <Button
            variant="outline-danger"
            onClick={handleCancel}
            className="fw-semibold border-0"
          >
            <XLg /> Cancel
          </Button>
        </Modal.Header>
        <Modal.Body>
          <Form
            onSubmit={handleSubmit}
            className="d-flex flex-column justify-content-evenly px-md-3"
          >
            <Form.Floating className="mb-1">
              <Form.Control
                className="border-0 border-bottom rounded-0 bg-white ps-md-4"
                type="text"
                placeholder="Product Name"
                name="name"
                value={product.name}
                onChange={handleInputChange}
                autoFocus
                required
              />
              <Form.Label>Product Name</Form.Label>
            </Form.Floating>

            <Form.Floating className="my-1">
              <Form.Control
                className="border-0 border-bottom rounded-0 bg-white ps-md-4"
                type="text"
                placeholder="Brand"
                name="brand"
                value={product.brand}
                onChange={handleInputChange}
                required
              />
              <Form.Label>Brand</Form.Label>
            </Form.Floating>

            <Form.Floating className="my-1">
              <Form.Control
                className="border-0 border-bottom rounded-0 bg-white ps-md-4"
                type="text"
                placeholder="Description"
                name="description"
                value={product.description}
                onChange={handleInputChange}
                required
              />
              <Form.Label>Description</Form.Label>
            </Form.Floating>

            <Form.Floating className="my-1">
              <Form.Control
                className="border-0 border-bottom rounded-0 bg-white ps-md-4"
                type="text"
                placeholder="Manufacturer Address"
                name="manufacturerAddress"
                value={product.manufacturerAddress}
                onChange={handleInputChange}
                required
              />
              <Form.Label>Manufacturer Address</Form.Label>
            </Form.Floating>

            <Form.Floating className="my-1">
              <Form.Control
                className="border-0 border-bottom rounded-0 bg-white ps-md-4"
                type="number"
                placeholder="Price"
                name="price"
                value={product.price}
                onChange={handleInputChange}
                required
              />
              <Form.Label>Price</Form.Label>
            </Form.Floating>

            <Form.Floating className="my-1">
              <Form.Control
                className="border-0 border-bottom rounded-0 bg-white ps-md-4"
                type="number"
                placeholder="Stocks"
                name="stocks"
                value={product.stocks}
                onChange={handleInputChange}
                required
              />
              <Form.Label>Stocks</Form.Label>
            </Form.Floating>

            <div className="d-flex">
              <Button
                variant="outline-danger"
                onClick={handleDelete}
                className="fw-semibold border-0 mt-md-4 my-2 w-100 me-md-2"
              >
                DELETE
              </Button>

              <Button
                variant="outline-success"
                type="submit"
                className="fw-semibold border-0 mt-md-4 my-2 w-100"
              >
                Save
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
}
