import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { registerUser } from "../services/userServices";
import RegisterForm from "../components/user/RegisterForm";
import { showErrorAlert, showSuccessAlert } from "../components/common/Alert";

//TODO: make the facebook and google work
export default function Register() {
  const navigate = useNavigate();

  const [userData, setUserData] = useState({
    firstName: "",
    lastName: "",
    mobileNo: "",
    address: "",
    email: "",
    password: "",
    confirmPassword: "",
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    setUserData({
      ...userData,
      [name]: value,
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (userData.password !== userData.confirmPassword) {
      showErrorAlert("Passwords do not match!");
      return;
    }

    try {
      await registerUser(userData);

      const message = [
        "Congratulations! You've made it to the other side. We're redirecting you to the login page now. Don't forget your password!",
        "You did it! You're officially a registered shopper. Keep calm and log in to get your shopping on.",
        "Hooray, you've joined the shopping elite! Your registration was successful, now log in and let the good times roll!",
        "Yey! You're now part of our shopping squad. Time to log in and start filling up that cart.",
        "Registration complete! Welcome to shopping paradise!, Log in now to put those shopping skills to the test!",
      ];
      const randomIndex = Math.floor(Math.random() * message.length);
      const randomMessage = message[randomIndex];
      showSuccessAlert(randomMessage);
      navigate("/login");
    } catch (error) {
      showErrorAlert(error);
    } finally {
      setUserData({
        firstName: "",
        lastName: "",
        mobileNo: "",
        address: "",
        email: "",
        password: "",
        confirmPassword: "",
      });
    }
  };

  return (
    <RegisterForm
      onSubmit={(event) => handleSubmit(event)}
      onChange={(event) => handleInputChange(event)}
      userData={userData}
    />
  );
}
