import axios from "axios";
import * as config from "../config";

// add product Image
export const postImage = async (productId, formData) => {
  try {
    const response = await axios.post(
      `${config.apiBaseUrl}/api/products/${productId}/images`,
      formData,
      {
        withCredentials: true,
      }
    );
    return response.data;
  } catch (error) {
    console.error("error", error);
    console.error("error.response", error.response);
    console.error("error.response.data", error.response.data);
    if (error.response.data.hasOwnProperty("details")) {
      const errorMessage = `${error.response.data.message} ${error.response.data.details}`;
      throw new Error(errorMessage || "Unable to fetch all products");
    } else {
      throw new Error(error.response.data.message || "Unable to post image");
    }
  }
};

// add product Image
export const deleteImage = async (productId, imageKey) => {
  try {
    const response = await axios.delete(
      `${config.apiBaseUrl}/api/products/${productId}/images/${imageKey}/`,
      {
        withCredentials: true,
      }
    );
    return response.data;
  } catch (error) {
    console.error("error", error);
    console.error("error.response", error.response);
    console.error("error.response.data", error.response.data);
    if (error.response.data.hasOwnProperty("details")) {
      const errorMessage = `${error.response.data.message} ${error.response.data.details}`;
      throw new Error(errorMessage || "Unable to fetch all products");
    } else {
      throw new Error(error.response.data.message || "Unable to delete image");
    }
  }
};

// fetch all products from api
export const fetchAllProducts = async (params) => {
  try {
    const response = await axios.get(`${config.apiBaseUrl}/api/products`, {
      withCredentials: true,
      params,
    });
    return response.data;
  } catch (error) {
    console.error("error", error);
    console.error("error.response", error.response);
    console.error("error.response.data", error.response.data);
    if (error.response.data.hasOwnProperty("details")) {
      const errorMessage = `${error.response.data.message} ${error.response.data.details}`;
      throw new Error(errorMessage || "Unable to fetch all products");
    } else {
      throw new Error(
        error.response.data.message || "Unable to fetch all products"
      );
    }
  }
};

// fetch single product from api
export const fetchSingleProduct = async (productId) => {
  try {
    const response = await axios.get(
      `${config.apiBaseUrl}/api/products/${productId}`,
      { withCredentials: true }
    );
    return response.data;
  } catch (error) {
    console.error("error", error);
    console.error("error.response", error.response);
    console.error("error.response.data", error.response.data);
    if (error.response.data.hasOwnProperty("details")) {
      const errorMessage = `${error.response.data.message} ${error.response.data.details}`;
      throw new Error(errorMessage || "Unable to fetch a product");
    } else {
      throw new Error(
        error.response.data.message || "Unable to fetch a product"
      );
    }
  }
};

// create a product
export const createProduct = async (createdProduct) => {
  try {
    const response = await axios.post(
      `${config.apiBaseUrl}/api/products`,
      createdProduct,
      { withCredentials: true }
    );
    return response.data;
  } catch (error) {
    console.error("error", error);
    console.error("error.response", error.response);
    console.error("error.response.data", error.response.data);
    if (error.response.data.hasOwnProperty("details")) {
      const errorMessage = `${error.response.data.message} ${error.response.data.details}`;
      throw new Error(errorMessage || "Unable to create a product");
    } else {
      throw new Error(
        error.response.data.message || "Unable to create a product"
      );
    }
  }
};

// update a product
export const updateProductDetails = async (productId, updatedProduct) => {
  try {
    const response = await axios.patch(
      `${config.apiBaseUrl}/api/products/${productId}`,
      updatedProduct,
      { withCredentials: true }
    );
    return response.data;
  } catch (error) {
    console.error("error", error);
    console.error("error.response", error.response);
    console.error("error.response.data", error.response.data);
    if (error.response.data.hasOwnProperty("details")) {
      const errorMessage = `${error.response.data.message} ${error.response.data.details}`;
      throw new Error(errorMessage || "Unable to update product");
    } else {
      throw new Error(
        error.response.data.message || "Unable to update product"
      );
    }
  }
};

// delete a product
export const deleteProduct = async (productId) => {
  try {
    const response = await axios.delete(
      `${config.apiBaseUrl}/api/products/${productId}`,
      { withCredentials: true }
    );
    return response.data;
  } catch (error) {
    console.error("error", error);
    console.error("error.response", error.response);
    console.error("error.response.data", error.response.data);
    if (error.response.data.hasOwnProperty("details")) {
      const errorMessage = `${error.response.data.message} ${error.response.data.details}`;
      throw new Error(errorMessage || "Unable to delete product");
    } else {
      throw new Error(
        error.response.data.message || "Unable to delete product"
      );
    }
  }
};
