import React, { useContext, useState } from "react";
import { Button, Container, Table } from "react-bootstrap";
import {
  Hourglass,
  HourglassBottom,
  HourglassSplit,
  HourglassTop,
  PersonFill,
  SlashCircle,
  SortDown,
  SortUp,
} from "react-bootstrap-icons";
import OrderStatusModal from "../../components/orders/OrderStatusModal";
import { OrderContext } from "../../contexts/OrderContext";

export default function OrdersUsersList() {
  const { allUsersOrders, setAllUsersOrders, setSelectedOrder } =
    useContext(OrderContext);
  const [sortKey, setSortKey] = useState("userName");
  const [sortOrder, setSortOrder] = useState("asc");
  const [showStatusModal, setShowStatusModal] = useState(false);

  const sortData = (key) => {
    if (sortKey === key) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortKey(key);
      setSortOrder("asc");
    }

    setAllUsersOrders((prevOrders) => {
      const ordersCopy = [...prevOrders];
      return ordersCopy.sort((a, b) => {
        let aValue = a[key];
        let bValue = b[key];

        if (key === "userName") {
          aValue = aValue.toLowerCase();
          bValue = bValue.toLowerCase();
        }

        if (sortOrder === "asc") {
          return aValue < bValue ? -1 : aValue > bValue ? 1 : 0;
        } else {
          return aValue < bValue ? 1 : aValue > bValue ? -1 : 0;
        }
      });
    });
  };

  const getSortIndicator = (key) => {
    if (sortKey === key) {
      return sortOrder === "asc" ? (
        <SortUp className="h5 me-md-2 m-0" />
      ) : (
        <SortDown className="h5 me-md-2 m-0" />
      );
    }
    return "";
  };

  const handleStatus = (order) => {
    setShowStatusModal(true);
    setSelectedOrder(order);
  };

  const renderStatusButton = (status, order) => {
    let variant;
    let icon;

    const handleClick = () => {
      handleStatus(order);
    };

    switch (status) {
      case "PENDING":
        variant = "outline-secondary";
        icon = <Hourglass />;
        break;
      case "PROCESSED":
        variant = "outline-info";
        icon = <HourglassTop />;
        break;
      case "SHIPPED":
        variant = "outline-primary";
        icon = <HourglassSplit />;
        break;
      case "DELIVERED":
        variant = "outline-success";
        icon = <HourglassBottom />;
        break;
      default:
        variant = "outline-danger";
        icon = <SlashCircle />;
    }

    return (
      <Button
        variant={variant}
        className="rounded-circle border-0 shadow-sm"
        onClick={handleClick}
      >
        {icon}
      </Button>
    );
  };

  return (
    <Container className="p-md-3 bg-white rounded">
      <p style={{ fontSize: "0.9rem" }} className="fw-semibold m-0">
        User: {allUsersOrders.length}
      </p>
      <div className="css-scroll my-2 border rounded">
        <Table bordered hover responsive className="text-center">
          <thead>
            <tr>
              <th
                onClick={() => sortData("userName")}
                style={{ cursor: "pointer" }}
              >
                <div className="d-flex justify-content-between">
                  User ID - User Name {getSortIndicator("userName")}
                </div>
              </th>
              <th>Order ID</th>
              <th>Purchased Items</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody className="css-user">
            {allUsersOrders.map((userOrder) =>
              userOrder.orders.map((order, orderIndex) => (
                <tr key={`${userOrder.userId}-${order.orderId}`}>
                  {orderIndex === 0 && (
                    <td rowSpan={userOrder.orders.length}>
                      <p className="m-0">
                        <PersonFill className="text-muted" />{" "}
                        {userOrder.userName}
                      </p>
                      {userOrder.userId}
                    </td>
                  )}
                  <td>{order.orderId}</td>
                  <td>
                    <div>
                      {`${order.purchasedProducts.length} ${
                        order.purchasedProducts.length <= 1 ? "item" : "item's"
                      }`}
                    </div>
                  </td>
                  <td>{renderStatusButton(order.status, order)}</td>
                </tr>
              ))
            )}
          </tbody>
        </Table>
      </div>
      <OrderStatusModal
        showStatusModal={showStatusModal}
        setShowStatusModal={setShowStatusModal}
      />
    </Container>
  );
}
