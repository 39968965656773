import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Form, Container, Row, Col, Button, Image } from "react-bootstrap";
import { Facebook, Google } from "react-bootstrap-icons";
import { Eye, EyeSlash } from "react-bootstrap-icons";
import { DEFAULT_IMAGE } from "../../utils/constants";

export default function RegisterForm({ onSubmit, onChange, userData }) {
  const [showPassword, setShowPassword] = useState(true);
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <Container className="py-md-4">
      <Row>
        <Col className="col-md-6 col-12 text-center my-4 my-md-0 d-flex flex-column justify-content-between border rounded bg-white shadow-sm p-2">
          <h3 className="m-0">Sign Up</h3>
          <div className="d-flex justify-content-evenly my-2 border-top pt-3">
            <Button variant="light" className="w-100">
              <Facebook className="me-2" />
              Facebook
            </Button>
            <p className="w-25 fw-semibold m-0">Or</p>
            <Button variant="light" className="w-100">
              <Google className="me-2" />
              Google
            </Button>
          </div>
          <Image src={DEFAULT_IMAGE} fluid className="rounded" />
        </Col>
        <Col className="col-md-6 d-flex p-0 px-md-2 mb-4 mb-md-0 rounded">
          <Form
            onSubmit={onSubmit}
            className="d-flex flex-column justify-content-between border py-md-3 px-md-3 p-2 rounded bg-white shadow-sm"
          >
            <Form.Floating className="mb-1">
              <Form.Control
                className="border-0 border-bottom rounded-0 px-md-5"
                type="text"
                placeholder="First Name"
                name="firstName"
                value={userData.firstName}
                onChange={onChange}
                required
              />
              <Form.Label>First Name</Form.Label>
            </Form.Floating>

            <Form.Floating className="my-1">
              <Form.Control
                className="border-0 border-bottom rounded-0 px-md-5"
                type="text"
                placeholder="Last Name"
                name="lastName"
                value={userData.lastName}
                onChange={onChange}
                required
              />
              <Form.Label>Last Name</Form.Label>
            </Form.Floating>

            <Form.Floating className="my-1">
              <Form.Control
                className="border-0 border-bottom rounded-0 px-md-5"
                type="number"
                placeholder="Mobile Number"
                name="mobileNo"
                value={userData.mobileNo}
                onChange={onChange}
                required
              />
              <Form.Label>Mobile Number</Form.Label>
            </Form.Floating>

            <Form.Floating className="my-1">
              <Form.Control
                className="border-0 border-bottom rounded-0 px-md-5"
                type="text"
                placeholder="Address"
                name="address"
                value={userData.address}
                onChange={onChange}
                required
              />
              <Form.Label>Address</Form.Label>
            </Form.Floating>

            <Form.Floating className="my-1">
              <Form.Control
                className="border-0 border-bottom rounded-0 px-md-5"
                type="email"
                placeholder="Email Address"
                name="email"
                value={userData.email}
                onChange={onChange}
                required
              />
              <Form.Label>Email address</Form.Label>
            </Form.Floating>

            <Form.Floating className="my-1">
              <Form.Control
                className="border-0 border-bottom rounded-0 px-md-5"
                type={showPassword ? "password" : "text"}
                placeholder="Password"
                name="password"
                value={userData.password}
                onChange={onChange}
                required
              />
              <Form.Label>Password</Form.Label>
              <div
                onClick={togglePasswordVisibility}
                className="position-absolute top-50 end-0 me-3 translate-middle-y border-0"
                style={{ cursor: "pointer", zIndex: 2, fontSize: "1.5rem" }}
              >
                {showPassword ? (
                  <EyeSlash className="text-secondary fw-bolder" />
                ) : (
                  <Eye className="text-dark fw-bolder" />
                )}
              </div>
            </Form.Floating>

            <Form.Floating className="my-1">
              <Form.Control
                className="border-0 border-bottom rounded-0 px-md-5"
                type={showPassword ? "password" : "text"}
                placeholder="Confirm Password"
                name="confirmPassword"
                value={userData.confirmPassword}
                onChange={onChange}
                required
              />
              <Form.Label>Confirm Password</Form.Label>
              <div
                onClick={togglePasswordVisibility}
                className="position-absolute top-50 end-0 me-3 translate-middle-y border-0"
                style={{ cursor: "pointer", zIndex: 2, fontSize: "1.5rem" }}
              >
                {showPassword ? (
                  <EyeSlash className="text-secondary fw-bolder" />
                ) : (
                  <Eye className="text-dark fw-bolder" />
                )}
              </div>
            </Form.Floating>
            <p
              className="text-muted text-center mt-2"
              style={{ fontSize: "0.9rem" }}
            >
              "We promise not to spill the beans on your credentials, even if
              someone offers us a lifetime supply of avocado toast!"
            </p>

            <Button variant="light" type="submit">
              Register
            </Button>

            <p
              className="text-muted text-center mt-3 mb-md-0"
              style={{ fontSize: "0.9rem" }}
            >
              Already have an account?{" "}
              <Link to="/login" className="h6 text-decoration-none">
                Log In
              </Link>
            </p>
          </Form>
        </Col>
      </Row>
    </Container>
  );
}
