import React, { useContext, useState } from "react";
import { Button, Container, Table } from "react-bootstrap";
import { BagX, BagCheckFill } from "react-bootstrap-icons";
import SearchBar from "../common/SearchBar";
import CartItemsUserModal from "./CartItemsUserModal";
import { CartContext } from "../../contexts/CartContext";
import { AuthContext } from "../../contexts/AuthContext";

export default function CartItemsList() {
  const { allUsersCarts, fetchSelectedUserCart, isUpdatedId } =
    useContext(CartContext);
  const { highLight } = useContext(AuthContext);
  const [showModal, setShowModal] = useState(false);
  const [searchKeyword, setSearchKeyword] = useState("");

  const handleToggleModal = (cartId) => {
    fetchSelectedUserCart(cartId);
    setShowModal(true);
  };

  const handleSearch = (searchValue) => {
    setSearchKeyword(searchValue);
  };

  const filteredUsersCarts = allUsersCarts.filter((cart) => {
    const regex = new RegExp(searchKeyword, "i");
    return regex.test(cart.userName);
  });

  return (
    <Container className="p-md-3 bg-white rounded">
      <p style={{ fontSize: "0.9rem" }} className="fw-semibold">
        Cart User's: {filteredUsersCarts.length}
      </p>
      <SearchBar
        onSearch={handleSearch}
        placeholder={"Search Keyword: User's Username."}
      />

      <div className="css-scroll my-3 border rounded">
        <Table hover responsive>
          <thead className="text-center">
            <tr>
              <th>News</th>
              <th>UserName</th>
              <th>UserId</th>
              <th>CartId</th>
              <th>CartItems</th>
            </tr>
          </thead>
          <tbody className="text-center">
            {filteredUsersCarts.map((cart) => (
              <tr
                key={cart._id}
                className={
                  cart.userId === highLight ? "css-initialadmin" : "css-user"
                }
              >
                <td>
                  {cart._id === isUpdatedId && (
                    <h6 className="m-0 badge bg-success my-2 py-1 text-white">
                      Updated
                    </h6>
                  )}
                </td>
                <td>{cart.userName}</td>
                <td>{cart.userId}</td>
                <td>{cart._id}</td>
                <td>
                  <Button
                    variant="light"
                    className="border-0 rounded-circle shadow-sm"
                    onClick={() => handleToggleModal(cart._id)}
                  >
                    {cart.cartItems.length === 0 ? (
                      <BagX
                        className={
                          cart.isAdmin ? "text-danger" : "text-secondary"
                        }
                      />
                    ) : (
                      <BagCheckFill
                        className={
                          cart.isAdmin ? "text-danger" : "text-primary"
                        }
                      />
                    )}
                  </Button>
                </td>
              </tr>
            ))}
            {filteredUsersCarts.length === 0 && (
              <tr>
                <td colSpan="6" className="text-center">
                  No Carts found.
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>
      <CartItemsUserModal showModal={showModal} setShowModal={setShowModal} />
    </Container>
  );
}
