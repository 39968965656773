import axios from "axios";
import * as config from "../config";

// fetch all cart from api
export const fetchAllCarts = async () => {
  try {
    const response = await axios.get(`${config.apiBaseUrl}/api/cart`, {
      withCredentials: true,
    });
    return response.data;
  } catch (error) {
    console.error("error", error);
    console.error("error.response", error.response);
    console.error("error.response.data", error.response.data);
    if (error.response.data.hasOwnProperty("details")) {
      const errorMessage = `${error.response.data.message} ${error.response.data.details}`;
      throw new Error(errorMessage || "Unable to fetch all carts");
    } else {
      throw new Error(
        error.response.data.message || "Unable to fetch all carts"
      );
    }
  }
};

// fetch a cart from api
export const fetchCart = async (cartId) => {
  try {
    const response = await axios.get(
      `${config.apiBaseUrl}/api/cart/${cartId}`,
      {
        withCredentials: true,
      }
    );
    return response.data;
  } catch (error) {
    console.error("error", error);
    console.error("error.response", error.response);
    console.error("error.response.data", error.response.data);
    if (error.response.data.hasOwnProperty("details")) {
      const errorMessage = `${error.response.data.message} ${error.response.data.details}`;
      throw new Error(errorMessage || "Unable to fetch a cart");
    } else {
      throw new Error(error.response.data.message || "Unable to fetch a cart");
    }
  }
};

// fetch add to cart from api
export const addToCart = async (cartId, itemToAdd) => {
  try {
    const response = await axios.post(
      `${config.apiBaseUrl}/api/cart/${cartId}`,
      itemToAdd,
      {
        withCredentials: true,
      }
    );
    return response.data;
  } catch (error) {
    console.error("error", error);
    console.error("error.response", error.response);
    console.error("error.response.data", error.response.data);
    if (error.response.data.hasOwnProperty("details")) {
      const errorMessage = `${error.response.data.message} ${error.response.data.details}`;
      throw new Error(errorMessage || "Unable to add products to cart");
    } else {
      throw new Error(
        error.response.data.message || "Unable to add products to cart"
      );
    }
  }
};

// fetch editCartItem
export const editCartItem = async (cartId, toUpdate) => {
  try {
    const response = await axios.patch(
      `${config.apiBaseUrl}/api/cart/${cartId}`,
      toUpdate,
      {
        withCredentials: true,
      }
    );
    return response.data;
  } catch (error) {
    console.error("error", error);
    console.error("error.response", error.response);
    console.error("error.response.data", error.response.data);
    if (error.response.data.hasOwnProperty("details")) {
      const errorMessage = `${error.response.data.message} ${error.response.data.details}`;
      throw new Error(errorMessage || "Unable to edit cart items");
    } else {
      throw new Error(
        error.response.data.message || "Unable to edit cart items"
      );
    }
  }
};

// fetch deleteAllCartItem
export const deleteAllCartItem = async (cartId) => {
  try {
    const response = await axios.delete(
      `${config.apiBaseUrl}/api/cart/${cartId}`,
      {
        withCredentials: true,
      }
    );
    return response.data;
  } catch (error) {
    console.error("error", error);
    console.error("error.response", error.response);
    console.error("error.response.data", error.response.data);
    if (error.response.data.hasOwnProperty("details")) {
      const errorMessage = `${error.response.data.message} ${error.response.data.details}`;
      throw new Error(errorMessage || "Unable to delete cart items");
    } else {
      throw new Error(
        error.response.data.message || "Unable to delete cart items"
      );
    }
  }
};
