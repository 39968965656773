import React, { useContext, useEffect, useState } from "react";
import { Button, Modal, Form } from "react-bootstrap";
import { AuthContext } from "../../contexts/AuthContext";
import { showErrorAlert, showSuccessAlert } from "../common/Alert";
import { XLg } from "react-bootstrap-icons";
import { deleteImage, postImage } from "../../services/productsServices";
import { ProductContext } from "../../contexts/ProductContext";

export default function ProductImageModal({
  product,
  showImageModal,
  handleCloseImageModal,
}) {
  const { isAuthenticated } = useContext(AuthContext);
  const { updateProduct, setTriggerUpdate, triggerUpdate } =
    useContext(ProductContext);
  const [imageFiles, setImageFiles] = useState([]);
  const [currentProduct, setCurrentProduct] = useState(product);

  useEffect(() => {
    if (product && showImageModal) {
      setCurrentProduct(product);
    }
  }, [product, showImageModal]);

  const handleImageChange = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setImageFiles(Array.from(e.target.files));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const formData = new FormData();
      for (const file of imageFiles) {
        formData.append("images", file);
      }

      if (isAuthenticated) {
        const { message, updatedProductWithImage } = await postImage(
          product._id,
          formData
        );

        setImageFiles([]);
        updateProduct(updatedProductWithImage);
        setCurrentProduct(updatedProductWithImage);
        setTriggerUpdate(!triggerUpdate);
        handleCloseImageModal();
        showSuccessAlert(message);
      }
    } catch (error) {
      showErrorAlert(error);
    }
  };

  const handleDeleteImage = async (imageKey) => {
    try {
      const { message, updatedProduct } = await deleteImage(
        product._id,
        imageKey
      );

      updateProduct(updatedProduct);
      setCurrentProduct(updatedProduct);
      setTriggerUpdate(!triggerUpdate);
      handleCloseImageModal();
      showSuccessAlert(message);
    } catch (error) {
      showErrorAlert(error);
    }
  };

  return (
    <>
      <Modal
        show={showImageModal}
        onHide={handleCloseImageModal}
        size="lg"
        centered
      >
        <Modal.Header>
          <Modal.Title className="h5">Add/Delete Images to Product</Modal.Title>
          <Button
            variant="outline-danger"
            onClick={handleCloseImageModal}
            className="m-0 border-0"
          >
            <XLg />
          </Button>
        </Modal.Header>
        <Modal.Body>
          <Form
            onSubmit={handleSubmit}
            className="d-flex flex-column justify-content-between align-items-center text-center"
          >
            <div className="image-container">
              {currentProduct.imageDetails?.length > 0 ? (
                currentProduct.imageDetails.map((image) => (
                  <div key={image._id} className="image-wrapper">
                    <img
                      src={image.location}
                      alt="Product"
                      className="rounded border"
                    />
                    <Button
                      variant="outline-danger"
                      className="border-0 rounded"
                      onClick={() => handleDeleteImage(image.key)}
                    >
                      Delete Image
                    </Button>
                  </div>
                ))
              ) : (
                <p className="text-muted">
                  Image not available or has been deleted.
                </p>
              )}
            </div>

            <Form.Group controlId="formImages" className="my-md-3">
              <div className="d-flex flex-column">
                <Form.Label>{product.name}</Form.Label>
                <Form.Label
                  className="text-muted"
                  style={{ fontSize: "0.9rem" }}
                >
                  Upload Images
                </Form.Label>
              </div>
              <Form.Control
                type="file"
                name="images"
                accept="image/*"
                className="my-2"
                onChange={handleImageChange}
                multiple
                required
              />
              <Form.Text className="text-muted">
                Maximum file size: 5 MB. Supported formats: JPG, PNG, GIF.
              </Form.Text>
            </Form.Group>
            <Button variant="primary" type="submit" className="mb-2">
              Save Images
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
}
