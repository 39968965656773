import React, { createContext, useState, useEffect, useContext } from "react";
import { AuthContext } from "./AuthContext";
import { fetchAllCarts, fetchCart } from "../services/cartServices";
import { UNIQUE_INITIAL_ADMIN_ID } from "../config";

export const CartContext = createContext();

export const CartProvider = ({ children }) => {
  const { isAuthenticated, currentUser, userType } = useContext(AuthContext);
  const [currentUserCart, setCurrentUserCart] = useState({ cartItems: [] });
  const [allUsersCarts, setAllUsersCarts] = useState([]);
  const [selectedUserCart, setSelectedUserCart] = useState({ cartItems: [] });
  const [isUpdatedId, setIsUpdatedId] = useState(null);

  useEffect(() => {
    const fetchCartItems = async () => {
      if (isAuthenticated && currentUser) {
        const { userCartDetails } = await fetchCart(currentUser.cartId);
        setCurrentUserCart(userCartDetails);
      }

      if (
        isAuthenticated &&
        (userType === "initialAdmin" || userType === "admin")
      ) {
        const { listOfAllUsersCarts } = await fetchAllCarts();

        const sortedList = listOfAllUsersCarts.sort((a, b) =>
          a.userId === UNIQUE_INITIAL_ADMIN_ID && userType === "initialAdmin"
            ? -1
            : 0
        );

        setAllUsersCarts(sortedList);
      }
    };

    fetchCartItems();
  }, [isAuthenticated, currentUser, userType]);

  const updateCurrentUserCart = (updatedCartItems) => {
    setCurrentUserCart((prevCart) => ({
      ...prevCart,
      cartItems: updatedCartItems,
    }));
  };

  const updateAllUsersCarts = (selectedCartId, updatedCartItems) => {
    setAllUsersCarts((prevAllUsersCarts) =>
      prevAllUsersCarts.map((cart) =>
        cart._id === selectedCartId
          ? { ...cart, cartItems: updatedCartItems }
          : cart
      )
    );

    setSelectedUserCart((prevSelectedUserCart) => {
      if (prevSelectedUserCart._id === selectedCartId) {
        setIsUpdatedId(selectedCartId);
        setTimeout(() => {
          setIsUpdatedId(null);
        }, 60000);
        return { ...prevSelectedUserCart, cartItems: updatedCartItems };
      }
      return prevSelectedUserCart;
    });
  };

  const fetchSelectedUserCart = async (selectedCartId) => {
    if (isAuthenticated && selectedCartId) {
      const { userCartDetails } = await fetchCart(selectedCartId);
      setSelectedUserCart(userCartDetails);
    }
  };

  return (
    <CartContext.Provider
      value={{
        currentUserCart,
        updateCurrentUserCart,
        allUsersCarts,
        updateAllUsersCarts,
        selectedUserCart,
        fetchSelectedUserCart,
        isUpdatedId,
      }}
    >
      {children}
    </CartContext.Provider>
  );
};
