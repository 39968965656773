import React from "react";
import Products from "./Products";

export default function Home() {
  return (
    <>
      <Products />
    </>
  );
}
