import React, { useContext, useState } from "react";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import LoginForm from "../components/user/LoginForm";
import { loginUser } from "../services/userServices";
import { AuthContext } from "../contexts/AuthContext";
import {
  showErrorToast,
  showSuccessToast,
} from "../components/common/ToastAlert";

export default function Login() {
  const { setIsAuthenticated } = useContext(AuthContext);
  const [, setCookie] = useCookies(["CURRENT_USER"]);
  const [rememberMe, setRememberMe] = useState(false);
  const navigate = useNavigate();
  const [userData, setUserData] = useState({
    email: localStorage.getItem("REMEMBERED_EMAIL") || "",
    password: "",
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    setUserData({
      ...userData,
      [name]: value,
    });
  };

  const handleRememberMeChange = (event) => {
    setRememberMe(event.target.checked);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      await loginUser(userData, setCookie);

      const message = [
        "Congrats, you just became a professional online shopper!",
        "Your login skills are so sharp, they could cut through a credit card.",
        "The SHOPOHS gods have blessed your login with success. Praise be!",
        "Welcome back, champ! Ready to fill your cart with goodies?",
        "You deserve a cookie for nailing that login!",
      ];
      const randomIndex = Math.floor(Math.random() * message.length);
      const randomMessage = message[randomIndex];

      if (rememberMe) {
        localStorage.setItem("REMEMBERED_EMAIL", userData.email);
      } else {
        localStorage.removeItem("REMEMBERED_EMAIL");
      }

      setIsAuthenticated(true);
      navigate("/");
      showSuccessToast(randomMessage);
    } catch (error) {
      if (error.message === "Email does not exist. Please sign up first.") {
        showErrorToast(error.message);
        navigate("/signup");
      }
      showErrorToast(error.message);
    } finally {
      setUserData({
        email: "",
        password: "",
      });
    }
  };

  return (
    <>
      <LoginForm
        onSubmit={(event) => handleSubmit(event)}
        onChange={(event) => handleInputChange(event)}
        rememberMe={rememberMe}
        handleRememberMeChange={(event) => handleRememberMeChange(event)}
        userData={userData}
      />
    </>
  );
}
