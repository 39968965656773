import React from "react";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter as Router } from "react-router-dom";
import { Routes, Route } from "react-router-dom";
import { Container } from "react-bootstrap";
import Header from "./components/common/Header";
import Home from "./pages/Home";
import Product from "./pages/Product";
import Register from "./pages/Register";
import Login from "./pages/Login";
import { AuthProvider } from "./contexts/AuthContext";
import AuthProtectedRoute from "./contexts/AuthProtectedRoute";
import { ProductProvider } from "./contexts/ProductContext";
import { Navigate } from "react-router-dom";
import Dashboard from "./pages/Dashboard";
import Cart from "./pages/Cart";
import Checkout from "./pages/Checkout";
import Footer from "./components/common/Footer";
import { UserUpdateProvider } from "./contexts/UserUpdateContext";
import LoadingPage from "./components/common/LoadingPage";
import NotFound from "./components/common/NotFound";
import { CustomToastContainer } from "./components/common/ToastAlert";
import { CartProvider } from "./contexts/CartContext";
import { OrderProvider } from "./contexts/OrderContext";

function App() {
  return (
    <div>
      <LoadingPage>
        <CustomToastContainer />
        <ProductProvider>
          <AuthProvider>
            <UserUpdateProvider>
              <CartProvider>
                <OrderProvider>
                  <Router>
                    <div className="d-flex flex-column min-vh-100">
                      <Header />
                      <Container className="flex-grow-1">
                        <Routes>
                          <Route
                            path="/"
                            element={<Navigate to="/SHOPOHS" />}
                          />
                          <Route path="/SHOPOHS" element={<Home />}></Route>
                          <Route path="/products" element={<Home />}></Route>
                          <Route
                            path="/products/:productId"
                            element={<Product />}
                          ></Route>
                          <Route path="/signup" element={<Register />}></Route>
                          <Route path="/login" element={<Login />}></Route>
                          <Route
                            path="/:usertype/dashboard/:userId"
                            element={
                              <AuthProtectedRoute>
                                <Dashboard />
                              </AuthProtectedRoute>
                            }
                          ></Route>
                          <Route
                            path="/:usertype/dashboard/:userId/cart/:cartId"
                            element={
                              <AuthProtectedRoute>
                                <Cart />
                              </AuthProtectedRoute>
                            }
                          ></Route>
                          <Route
                            path="/:usertype/dashboard/:userId/orders"
                            element={
                              <AuthProtectedRoute>
                                <Checkout />
                              </AuthProtectedRoute>
                            }
                          ></Route>
                          <Route path="*" element={<NotFound />} />
                        </Routes>
                      </Container>
                      <Footer />
                    </div>
                  </Router>
                </OrderProvider>
              </CartProvider>
            </UserUpdateProvider>
          </AuthProvider>
        </ProductProvider>
      </LoadingPage>
    </div>
  );
}

export default App;
