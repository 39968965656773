import React, { useContext, useState } from "react";
import ProfileDetails from "../components/user/ProfileDetails";
import { Row, Col, Tab, Tabs, Container, Image, Nav } from "react-bootstrap";
import { AuthContext } from "../contexts/AuthContext";
import ProductsActive from "../components/products/ProductsActive";
import ProductsInactive from "../components/products/ProductsInactive";
import ProfilesList from "../components/user/ProfilesList";
import CartItems from "../components/cart/CartItems";
import CartItemsList from "../components/cart/CartItemsList";
import OrderList from "../components/orders/OrderList";
import OrdersUsersList from "../components/orders/OrdersUsersList";
import ProfileImageModal from "../components/user/ProfileImageModal";
import { FaEdit } from "react-icons/fa";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

export default function Dashboard() {
  const { userType, currentUser } = useContext(AuthContext);
  const isInitialAdmin = userType === "initialAdmin";
  const isAdmin = userType === "admin";
  const isAdministrators = isInitialAdmin || isAdmin;

  const [showImageModal, setShowImageModal] = useState(false);
  const handleShowImageModal = () => setShowImageModal(true);
  const handleCloseImageModal = () => setShowImageModal(false);

  const imageWrapperStyles = {
    position: "relative",
    display: "inline-block",
  };

  return (
    <>
      <Tab.Container defaultActiveKey="profile">
        <Row className="p-md-4 p-2 justify-content-between">
          <Col className="col-md-2 col-12 p-2 text-center bg-white rounded shadow">
            <Container>
              <div className="mt-md-5">
                <div style={imageWrapperStyles} onClick={handleShowImageModal}>
                  <Image
                    src={
                      currentUser.imageDetails &&
                      currentUser.imageDetails.location
                        ? currentUser.imageDetails.location
                        : "../../../../images/icon.png"
                    }
                    fluid
                    className="rounded-circle border shadow-sm"
                  />
                  <div className="icon-container">
                    <OverlayTrigger
                      key="top"
                      placement="top"
                      overlay={
                        <Tooltip id="tooltip-top">Edit profile image</Tooltip>
                      }
                    >
                      <div>
                        <FaEdit className="icon" />
                      </div>
                    </OverlayTrigger>
                  </div>
                </div>
                <div className="mt-3">
                  <h6>
                    {currentUser.firstName} {currentUser.lastName}
                  </h6>
                  <p
                    className={`m-0 ${
                      isInitialAdmin
                        ? "text-danger"
                        : isAdmin
                        ? "text-primary"
                        : "text-secondary"
                    }`}
                  >
                    {isInitialAdmin
                      ? "Initial Admin"
                      : isAdmin
                      ? "Admin"
                      : "User"}
                  </p>
                </div>
                <Nav variant="pills" className="d-flex flex-column mt-md-4">
                  <Nav.Item>
                    <Nav.Link className="my-1 p-1" eventKey="profile">
                      Profile
                    </Nav.Link>
                  </Nav.Item>

                  {isAdministrators ? (
                    <Nav.Item>
                      <Nav.Link className="my-1 p-1" eventKey="products">
                        Products
                      </Nav.Link>
                    </Nav.Item>
                  ) : null}

                  <Nav.Item>
                    <Nav.Link className="my-1 p-1" eventKey="cart">
                      Cart
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link className="my-1 p-1" eventKey="orders">
                      Orders
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
              </div>
            </Container>
          </Col>
          <Col className="col-md-10 col-12 rounded p-0 px-md-3 mt-2 mt-md-0">
            <Tab.Content className="border p-0 m-0 rounded shadow bg-white">
              <Tab.Pane eventKey="profile" className="m-0 p-0 rounded">
                <Tabs defaultActiveKey="myProfile" justify className="rounded">
                  <Tab
                    eventKey="myProfile"
                    title="My Profile"
                    className={!isAdministrators && "border"}
                  >
                    <ProfileDetails />
                  </Tab>
                  {isAdministrators ? (
                    <Tab eventKey="users" title="List of User's">
                      <ProfilesList />
                    </Tab>
                  ) : null}
                </Tabs>
              </Tab.Pane>

              {isAdministrators ? (
                <Tab.Pane eventKey="products" className="m-0 p-0 rounded">
                  <Tabs defaultActiveKey="active" justify className="rounded">
                    <Tab eventKey="active" title="Active Products">
                      <ProductsActive />
                    </Tab>
                    <Tab eventKey="inactive" title="Inactive Products">
                      <ProductsInactive />
                    </Tab>
                  </Tabs>
                </Tab.Pane>
              ) : null}

              <Tab.Pane eventKey="cart" className="m-0 p-0 rounded">
                <Tabs defaultActiveKey="myCart" justify className="rounded">
                  <Tab
                    eventKey="myCart"
                    title="My Cart"
                    className={!isAdministrators && "border"}
                  >
                    <CartItems customStyle={"my-md-0"} />
                  </Tab>
                  {isAdministrators ? (
                    <Tab eventKey="usersCarts" title="User's Carts">
                      <CartItemsList />
                    </Tab>
                  ) : null}
                </Tabs>
              </Tab.Pane>
              <Tab.Pane eventKey="orders" className="m-0 p-0 rounded">
                <Tabs defaultActiveKey="myOrders" justify className="rounded">
                  <Tab eventKey="myOrders" title="My Orders">
                    <OrderList
                      customStyle={
                        isAdministrators ? "m-0 p-3" : "m-0 p-3 pt-0"
                      }
                    />
                  </Tab>
                  {isAdministrators ? (
                    <Tab eventKey="usersOrderList" title="User's Order List">
                      <OrdersUsersList />
                    </Tab>
                  ) : null}
                </Tabs>
              </Tab.Pane>
            </Tab.Content>
          </Col>
        </Row>
      </Tab.Container>
      <ProfileImageModal
        showImageModal={showImageModal}
        handleCloseImageModal={handleCloseImageModal}
      />
    </>
  );
}
