import { createContext, useState } from "react";

export const UserUpdateContext = createContext();

export const UserUpdateProvider = ({ children }) => {
  const [forceUpdate, setForceUpdate] = useState(0);
  const [updatedUsers, setUpdatedUsers] = useState({});

  const handleForceUpdate = (id) => {
    setForceUpdate((prev) => prev + 1);
    setUpdatedUsers((prev) => ({ ...prev, [id]: true }));
  };

  const removeUpdatedUser = (id) => {
    setUpdatedUsers((prev) => {
      const newUpdatedUsers = { ...prev };
      delete newUpdatedUsers[id];
      return newUpdatedUsers;
    });
  };

  return (
    <UserUpdateContext.Provider
      value={{
        forceUpdate,
        updatedUsers,
        handleForceUpdate,
        removeUpdatedUser,
      }}
    >
      {children}
    </UserUpdateContext.Provider>
  );
};
