import React, { useContext, useState } from "react";
import { Button, Modal, Form } from "react-bootstrap";
import { AuthContext } from "../../contexts/AuthContext";
import { showErrorAlert, showSuccessAlert } from "../common/Alert";
import { XLg } from "react-bootstrap-icons";
import { addUpdateUserImage } from "../../services/userServices";

export default function ProfileImageModal({
  showImageModal,
  handleCloseImageModal,
}) {
  const { isAuthenticated, currentUser, setCurrentUser } =
    useContext(AuthContext);
  const [imageFile, setImageFile] = useState([]);

  const handleImageChange = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setImageFile(e.target.files);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const formData = new FormData();
      formData.append("images", imageFile[0]);

      if (isAuthenticated) {
        const { message, updatedUserWithImage } = await addUpdateUserImage(
          currentUser._id,
          formData
        );

        setImageFile(null);
        setCurrentUser(updatedUserWithImage);
        handleCloseImageModal();
        showSuccessAlert(message);
      }
    } catch (error) {
      showErrorAlert(error);
    }
  };

  return (
    <>
      <Modal
        show={showImageModal}
        onHide={handleCloseImageModal}
        size="lg"
        centered
      >
        <Modal.Header>
          <Modal.Title className="h5">Change Profile Image?</Modal.Title>
          <Button
            variant="outline-danger"
            onClick={handleCloseImageModal}
            className="m-0 border-0"
          >
            <XLg />
          </Button>
        </Modal.Header>
        <Modal.Body>
          <Form
            onSubmit={handleSubmit}
            className="d-flex flex-column justify-content-between align-items-center text-center"
          >
            <Form.Group controlId="formImage" className="my-md-3">
              <Form.Label className="text-muted" style={{ fontSize: "0.9rem" }}>
                Upload the desired Image
              </Form.Label>
              <Form.Control
                type="file"
                name="images"
                accept="image/*"
                className="my-2"
                onChange={handleImageChange}
                required
              />
              <Form.Text className="text-muted">
                Maximum file size: 5 MB. Supported formats: JPG, PNG, GIF.
              </Form.Text>
            </Form.Group>
            <Button variant="primary" type="submit" className="mb-2">
              Save Image
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
}
