import React, { useContext, useState } from "react";
import { Button, FloatingLabel, Form, Modal } from "react-bootstrap";
import { XLg } from "react-bootstrap-icons";
import { createProduct } from "../../services/productsServices";
import { showErrorAlert, showSuccessAlert } from "../common/Alert";
import { ProductContext } from "../../contexts/ProductContext";

export default function ProductCreationModal({
  showAddProductModal,
  handleCloseAddProductModal,
}) {
  const [product, setProduct] = useState({
    name: "",
    brand: "",
    description: "",
    manufacturerAddress: "",
    price: "",
    stocks: "",
  });

  const { updateProduct } = useContext(ProductContext);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setProduct({ ...product, [name]: value });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const { message, createdProduct } = await createProduct(product);

      setProduct({
        name: "",
        brand: "",
        description: "",
        manufacturerAddress: "",
        price: "",
        stocks: "",
      });

      updateProduct(createdProduct);
      showSuccessAlert(message);
      handleCloseAddProductModal();
    } catch (error) {
      showErrorAlert(error);
    }
  };

  const clearForm = () => {
    setProduct({
      name: "",
      brand: "",
      description: "",
      manufacturerAddress: "",
      price: "",
      stocks: "",
    });
  };

  const handleCancel = () => {
    clearForm();
    handleCloseAddProductModal();
  };

  return (
    <>
      <Modal
        show={showAddProductModal}
        onHide={handleCloseAddProductModal}
        size="md"
        centered
      >
        <Modal.Header>
          <Modal.Title>Add New Product</Modal.Title>
          <Button
            variant="white"
            onClick={handleCancel}
            className="fw-semibold text-danger border-0 btn-outline-light"
          >
            <XLg /> Cancel
          </Button>
        </Modal.Header>
        <Modal.Body>
          <Form
            onSubmit={handleSubmit}
            className="d-flex flex-column justify-content-evenly px-md-3"
          >
            <Form.Floating className="mb-1">
              <Form.Control
                className="border-0 border-bottom rounded-0 bg-white ps-md-4"
                type="text"
                placeholder="Product Name"
                name="name"
                value={product.name}
                onChange={handleInputChange}
                required
              />
              <Form.Label>Product Name</Form.Label>
            </Form.Floating>

            <Form.Floating className="my-1">
              <Form.Control
                className="border-0 border-bottom rounded-0 bg-white ps-md-4"
                type="text"
                placeholder="Brand"
                name="brand"
                value={product.brand}
                onChange={handleInputChange}
                required
              />
              <Form.Label>Brand</Form.Label>
            </Form.Floating>

            <FloatingLabel
              controlId="floatingTextarea"
              label="Description"
              className="mb-3"
            >
              <Form.Control
                as="textarea"
                className="border-0 border-bottom rounded-0 bg-white ps-md-4 my-md-2"
                type="text"
                placeholder="Description"
                name="description"
                value={product.description}
                onChange={handleInputChange}
                style={{ height: "150px" }}
                required
              />
            </FloatingLabel>

            <Form.Floating className="my-1">
              <Form.Control
                className="border-0 border-bottom rounded-0 bg-white ps-md-4"
                type="text"
                placeholder="Manufacturer Address"
                name="manufacturerAddress"
                value={product.manufacturerAddress}
                onChange={handleInputChange}
                required
              />
              <Form.Label>Manufacturer Address</Form.Label>
            </Form.Floating>

            <Form.Floating className="my-1">
              <Form.Control
                className="border-0 border-bottom rounded-0 bg-white ps-md-4"
                type="number"
                placeholder="Price"
                name="price"
                value={product.price}
                onChange={handleInputChange}
                required
              />
              <Form.Label>Price</Form.Label>
            </Form.Floating>

            <Form.Floating className="my-1">
              <Form.Control
                className="border-0 border-bottom rounded-0 bg-white ps-md-4"
                type="number"
                placeholder="Stocks"
                name="stocks"
                value={product.stocks}
                onChange={handleInputChange}
                required
              />
              <Form.Label>Stocks</Form.Label>
            </Form.Floating>

            <Button
              variant="white"
              type="submit"
              className="fw-semibold text-success border-0 mt-md-4 my-2 btn-outline-light"
            >
              Save
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
}
