import React, { useContext, useEffect, useState } from "react";
import { ProductContext } from "../contexts/ProductContext";
import { Container, Row, Col, Card, Carousel } from "react-bootstrap";
import { Link } from "react-router-dom";

export default function Products() {
  const { activeProductList, searchKeyword } = useContext(ProductContext);
  const [searchResults, setSearchResults] = useState(activeProductList);
  const [isInitialRender, setIsInitialRender] = useState(true);

  useEffect(() => {
    if (searchKeyword === "") {
      setSearchResults(activeProductList);
      setIsInitialRender(true);
    } else {
      const filteredProducts = activeProductList.filter((product) => {
        const regex = new RegExp(searchKeyword, "i");
        return (
          regex.test(product.name) ||
          regex.test(product.brand) ||
          regex.test(product.description)
        );
      });
      setSearchResults(filteredProducts);
      setIsInitialRender(false);
    }
  }, [searchKeyword, activeProductList]);

  return (
    <>
      <Container className="d-flex flex-column justify-content-center align-items-center p-0">
        {searchResults.length !== 0 || isInitialRender ? (
          <>
            <Row className="special-features py-3 mt-md-5 mb-md-0 mb-3 mt-4 bg-light">
              <Col className="col-12">
                <h1 className="text-center mb-3 special-features-title">
                  Featured Products
                </h1>
                <Carousel indicators={false}>
                  {searchResults.map((product) =>
                    product.imageDetails.map((image, index) => (
                      <Carousel.Item key={image._id}>
                        <img
                          src={image.location}
                          alt={`Product ${product.name} - ${index + 1}`}
                          className="d-block w-100"
                        />
                        <Carousel.Caption className="p-0">
                          <h5 className="special-feature-title m-0">
                            {product.name} - Image {index + 1}
                          </h5>
                        </Carousel.Caption>
                      </Carousel.Item>
                    ))
                  )}
                </Carousel>
              </Col>
            </Row>
            <Row className="py-md-5 py-2 d-flex flex-row justify-content-center align-items-stretch w-100">
              {searchResults.map((product) => (
                <Col
                  key={product._id}
                  className="col-md-3 col-lg-3 col-sm-6 col-6 my-md-2 my-1 px-1"
                >
                  <Link
                    to={`/products/${product._id}`}
                    className="text-decoration-none text-dark product-card-link"
                  >
                    <Card className="product-card shadow w-100 h-100">
                      <Card.Img
                        variant="top"
                        src={product.imageDetails[0]?.location}
                        alt={product.name}
                        className="product-image"
                      />
                      <Card.Body
                        className="text-center d-flex flex-column justify-content-between p-md-3 p-2
                      "
                      >
                        <Card.Title className="h6 product-name">
                          {product.name}
                        </Card.Title>
                        <Card.Text className="product-description">
                          {product.description}
                        </Card.Text>
                        <Card.Text className="product-price font-weight-bold">
                          ₱ {product.price}
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </Link>
                </Col>
              ))}
            </Row>
          </>
        ) : (
          <>
            <div className="h5 text-center text-muted mt-md-5">
              No product matched the search criteria...
            </div>
          </>
        )}
      </Container>
    </>
  );
}
