import { useCookies } from "react-cookie";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../contexts/AuthContext";
import { logoutUser } from "../services/userServices";
import {
  showErrorToast,
  showSuccessToast,
} from "../components/common/ToastAlert";

export const useLogout = () => {
  const navigate = useNavigate();
  const [, , removeCookie] = useCookies(["access_token", "CURRENT_USER"]);
  const { setIsAuthenticated, setCurrentUser } = useContext(AuthContext);

  const handleLogout = async () => {
    try {
      const { message } = await logoutUser();

      const cookieOptions = { path: "/" };
      removeCookie("access_token", cookieOptions);
      removeCookie("CURRENT_USER", cookieOptions);
      setIsAuthenticated(false);
      setCurrentUser(null);

      showSuccessToast(message);
      navigate("/login");
    } catch (error) {
      showErrorToast(error.message || error);
    }
  };

  return handleLogout;
};
