import React, { useContext } from "react";
import { Button, Card, Col, Image, Modal, Row } from "react-bootstrap";
import { AuthContext } from "../../contexts/AuthContext";
import { deleteAllCartItem, editCartItem } from "../../services/cartServices";
import { Trash, XLg } from "react-bootstrap-icons";
import { showWarningAlert } from "../common/Alert";
import { CartContext } from "../../contexts/CartContext";
import { showErrorToast, showSuccessToast } from "../common/ToastAlert";

export default function CartItemsUserModal({ showModal, setShowModal }) {
  const { isAuthenticated } = useContext(AuthContext);
  const { selectedUserCart, updateAllUsersCarts } = useContext(CartContext);

  const handleClose = () => setShowModal(false);

  const handleConfirmation = () => {
    const title = "Are you sure?";
    const message = `You are about to remove all the items from ${selectedUserCart.userName}'s cart.`;
    showWarningAlert(title, message, handleRemoveAllCartItem);
  };

  const handleRemoveAllCartItem = async () => {
    try {
      if (isAuthenticated) {
        const { deleteAllCartItems } = await deleteAllCartItem(
          selectedUserCart._id
        );

        updateAllUsersCarts(selectedUserCart._id, deleteAllCartItems);
        showSuccessToast(
          `All item's from ${selectedUserCart.userName}'s cart has been removed.`
        );
      }
    } catch (error) {
      showErrorToast(error.message || error);
    }
  };

  const handleRemoveItem = async (e, cartItemId) => {
    e.preventDefault();
    try {
      if (isAuthenticated) {
        const { message, updatedCartItems } = await editCartItem(
          selectedUserCart._id,
          {
            cartItemId,
          }
        );

        updateAllUsersCarts(selectedUserCart._id, updatedCartItems);
        showSuccessToast(
          `${message} from ${selectedUserCart.userName}'s cart.`
        );
      }
    } catch (error) {
      showErrorToast(error.message || error);
    }
  };

  return (
    <>
      <Modal
        show={showModal}
        onHide={handleClose}
        size="lg"
        centered
        scrollable={true}
      >
        <Modal.Header className="px-md-3 p-2">
          <div className="w-75">
            <h5 className="m-0">{selectedUserCart.userName}</h5>
            <p className="text-muted fw-semibold m-0">
              Cart Items: {selectedUserCart.cartItems.length}
            </p>
          </div>
          <div className="w-25 d-flex justify-content-between align-items-center flex-md-row flex-column-reverse">
            <Button
              variant="outline-danger"
              className="border-0 p-0 p-md-1"
              onClick={handleConfirmation}
            >
              Remove All
            </Button>
            <Button
              className="bg-white text-muted border-0 p-0 p-md-1"
              onClick={handleClose}
            >
              <XLg /> Close
            </Button>
          </div>
        </Modal.Header>
        <Modal.Body>
          {selectedUserCart.cartItems.map((item) => (
            <Card key={item._id} className="mb-3">
              <Card.Header className="d-flex justify-content-between align-items-center p-2 px-md-2 py-md-1">
                <div className="m-0 d-flex flex-md-row flex-column">
                  <h6 className="m-0 me-md-1">{`${item.productBrand} >`}</h6>
                  <h6 className="m-0">{item.productName}</h6>
                </div>
                <Button
                  variant="outline-danger"
                  className="border-0 p-1 px-md-2"
                  onClick={(e) => handleRemoveItem(e, item._id)}
                >
                  <Trash />
                </Button>
              </Card.Header>
              <Card.Body className="d-md-flex flex-md-column p-2">
                <Row className="d-flex justify-content-center align-items-center p-0 m-0">
                  <Col md={2} className="p-0 my-md-0 my-1">
                    <Image
                      src={item.imageUrl}
                      fluid
                      className="rounded border d-block m-auto d-md-inline m-md-0"
                      style={{ height: "70px" }}
                    />
                  </Col>
                  <Col md={10} className="p-0">
                    <p className="text-muted m-0">
                      From: {item.manufacturerAddress}
                    </p>
                    <div className="d-flex justify-content-between border-top mt-md-2 pt-md-2">
                      <Card.Text className="m-0">
                        Price: {item.productPrice}
                      </Card.Text>
                      <Card.Text className="m-0">
                        Total Price: {item.totalPrice}
                      </Card.Text>
                      <Card.Text className="m-0">
                        Quantity: {item.quantity}
                      </Card.Text>
                    </div>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          ))}
          {selectedUserCart.cartItems.length === 0 ? (
            <h4 className="text-center text-muted my-md-5">
              {selectedUserCart.userName}'s cart is empty.
            </h4>
          ) : null}
        </Modal.Body>
      </Modal>
    </>
  );
}
