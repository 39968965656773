import React, { useContext, useEffect, useState } from "react";
import { Button, Container, Table } from "react-bootstrap";
import { updateProductDetails } from "../../services/productsServices";
import { Link } from "react-router-dom";
import { showErrorAlert } from "../common/Alert";
import SearchBar from "../common/SearchBar";
import { Archive, Pencil, Search } from "react-bootstrap-icons";
import { ProductContext } from "../../contexts/ProductContext";
import ProductUpdateModal from "./ProductUpdateModal";

//Todo: put the latest unarchive product at the top as new
//Todo: make the searchbar work
export default function ProductsInactive() {
  const {
    inactiveProductList,
    triggerUpdate,
    setTriggerUpdate,
    searchKeyword,
    setSearchKeyword,
    setActiveProductList,
    setInactiveProductList,
  } = useContext(ProductContext);

  const [currentProduct, setCurrentProduct] = useState(null);
  const [searchResults, setSearchResults] = useState(inactiveProductList);
  const [showEditProductModal, setShowEditProductModal] = useState(false);
  const handleCloseEditProductModal = () => setShowEditProductModal(false);
  const handleShowEditProductModal = (product) => {
    setCurrentProduct(product);
    setShowEditProductModal(true);
  };

  useEffect(() => {
    if (searchKeyword === "") {
      setSearchResults(inactiveProductList);
    } else {
      const filteredProducts = inactiveProductList.filter((product) => {
        const regex = new RegExp(searchKeyword, "i");
        return (
          regex.test(product.name) ||
          regex.test(product.brand) ||
          regex.test(product.description)
        );
      });
      setSearchResults(filteredProducts);
    }
  }, [searchKeyword, inactiveProductList, setSearchResults]);

  const handleProductSearch = (searchValue) => {
    setSearchKeyword(searchValue);
  };

  const handleUnarchive = async (productId) => {
    try {
      await updateProductDetails(productId, { isActive: true });
      const product = inactiveProductList.find((p) => p._id === productId);
      setInactiveProductList((prevProductList) =>
        prevProductList.filter((product) => product._id !== productId)
      );
      setActiveProductList((prevProductList) => [
        ...prevProductList,
        { ...product, isActive: true },
      ]);
      setTriggerUpdate(!triggerUpdate);
    } catch (error) {
      showErrorAlert(error);
    }
  };

  const sortedProductList = searchResults.sort(
    (a, b) => new Date(b.createdOn) - new Date(a.createdOn)
  );

  return (
    <Container className="p-md-3 bg-white">
      <p style={{ fontSize: "0.9rem" }} className="fw-semibold">
        Products: {sortedProductList.length}
      </p>
      <SearchBar
        onSearch={handleProductSearch}
        placeholder={"Search Keyword: Products name, brand or description."}
      />

      <div className="css-scroll my-3 border rounded">
        <Table hover responsive>
          <thead className="text-center">
            <tr>
              <th>News</th>
              <th>ProductId</th>
              <th>ProductName</th>
              <th>Unarchive</th>
              <th>Edit</th>
              <th>View</th>
            </tr>
          </thead>
          <tbody className="text-center">
            {sortedProductList.map((product) => (
              <tr key={product._id}>
                <td>
                  {product.isUpdated && (
                    <h6 className="m-0 badge bg-danger my-2 py-1 text-white small">
                      Updated
                    </h6>
                  )}
                </td>
                <td>{product._id}</td>
                <td>{product.name}</td>
                <td>
                  <Button
                    variant="light"
                    className="border-0 rounded-circle text-danger shadow-sm"
                    onClick={() => handleUnarchive(product._id)}
                  >
                    <Archive />
                  </Button>
                </td>
                <td>
                  <Button
                    variant="light"
                    className="border-0 rounded-circle shadow-sm"
                    onClick={() => handleShowEditProductModal(product)}
                  >
                    <Pencil />
                  </Button>
                </td>
                <td>
                  <Button
                    as={Link}
                    to={`/products/${product._id}`}
                    variant="light"
                    className="border-0 rounded-circle shadow-sm"
                  >
                    <Search />
                  </Button>
                </td>
              </tr>
            ))}
            {sortedProductList.length === 0 && (
              <tr>
                <td colSpan="6" className="text-center">
                  No Inactive products found.
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>
      <ProductUpdateModal
        showEditProductModal={showEditProductModal}
        handleCloseEditProductModal={handleCloseEditProductModal}
        currentProduct={currentProduct}
      />
    </Container>
  );
}
