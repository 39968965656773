import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { Link } from "react-router-dom";

export default function NotFound() {
  return (
    <Container>
      <Row className="vh-100 align-items-center">
        <Col className="text-center">
          <h1 className="flip">404</h1>
          <h3>Oops! Page not found.</h3>
          <p>The page you are looking for does not exist or has been moved.</p>
          <Link to="/">
            <Button variant="primary">Go to Homepage</Button>
          </Link>
        </Col>
      </Row>
    </Container>
  );
}
