import React, { useState } from "react";
import { Form, Button } from "react-bootstrap";
import { Search } from "react-bootstrap-icons";

export default function SearchBar({ customStyle, onSearch, placeholder }) {
  const style = customStyle ? customStyle : "";
  const [searchValue, setSearchValue] = useState("");

  const handleSearch = (event) => {
    event.preventDefault();
    onSearch(searchValue);
  };

  return (
    <Form
      onSubmit={handleSearch}
      className={`d-flex mx-auto border rounded bg-white ${style}`}
    >
      <Form.Control
        type="search"
        name="search"
        placeholder={placeholder}
        className="border-0 rounded"
        value={searchValue}
        onChange={(e) => setSearchValue(e.target.value)}
      />
      <Button type="submit" variant="light" className="rounded ms-2">
        <Search />
      </Button>
    </Form>
  );
}
