import React, { useContext } from "react";
import { Container, Navbar, Nav, Image } from "react-bootstrap";
import { CartFill } from "react-bootstrap-icons";
import { Link, useLocation } from "react-router-dom";
import Logout from "../user/Logout";
import { AuthContext } from "../../contexts/AuthContext";
import SearchBar from "./SearchBar";
import { ProductContext } from "../../contexts/ProductContext";
import { CartContext } from "../../contexts/CartContext";

export default function Header() {
  const { isAuthenticated, currentUser, userType } = useContext(AuthContext);
  const { currentUserCart } = useContext(CartContext);
  const location = useLocation();
  const { setSearchKeyword } = useContext(ProductContext);

  const isOnDefault = location.pathname === "/";
  const isOnHomePage = location.pathname === "/SHOPOHS";
  const isOnSignupPage = location.pathname === "/signup";
  const isOnLoginPage = location.pathname === "/login";
  const isOnProfilePage =
    location.pathname === `/${userType}/dashboard/${currentUser?._id}`;
  const handleClearSearch = () => {
    setSearchKeyword("");
  };

  const handleProductSearch = (searchValue) => {
    setSearchKeyword(searchValue);
  };

  return (
    <Navbar
      bg="dark"
      variant="dark"
      expand="lg"
      className="bg-light sticky-top"
    >
      <Container className="d-flex justify-content-between w-100">
        <Navbar.Brand
          onClick={handleClearSearch}
          as={Link}
          to="/"
          className="css-brand"
        >
          SHOPOHS
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          {!isOnHomePage ||
            (!isOnDefault && (
              <SearchBar
                customStyle={"col-lg-8 mt-2 mt-lg-0 p-lg-1"}
                onSearch={handleProductSearch}
                placeholder={
                  "Search Keyword: Products name, brand or description."
                }
              />
            ))}

          <Nav className="ms-auto d-flex justify-content-center align-items-centers">
            {isAuthenticated && !isOnProfilePage && currentUser?.firstName && (
              <>
                <Nav.Link
                  as={Link}
                  to={`/${userType}/dashboard/${currentUser?._id}`}
                >
                  <Navbar.Brand className="m-0">
                    <div className="d-md-flex d-none p-0 m-0">
                      <Image
                        src={
                          currentUser.imageDetails &&
                          currentUser.imageDetails.location
                            ? currentUser.imageDetails.location
                            : "/../../images/icon.png"
                        }
                        fluid
                        className="rounded-circle border me-3 p-0"
                        style={{ height: "45px" }}
                      />
                      <h4 className="my-2">{currentUser.firstName}</h4>
                    </div>
                    <span className="d-inline d-sm-none h6 text-white fw-semibold m-0">
                      My Profile
                    </span>
                  </Navbar.Brand>
                </Nav.Link>
                <div className="border-end d-md-block d-sm-none mx-md-2 my-md-2"></div>
                <div className="border-bottom d-sm-block d-md-none"></div>
                <Nav.Link
                  as={Link}
                  to={`/${userType}/dashboard/${currentUser?._id}/cart/${currentUser?.cartId}`}
                >
                  <Navbar.Brand className="d-flex">
                    <div style={{ position: "relative" }}>
                      <CartFill
                        style={{ fontSize: "1.9rem" }}
                        className="text-white d-none d-sm-inline"
                      />
                      {currentUserCart.cartItems.length > 0 && (
                        <span className="css-cartItems rounded d-none d-sm-inline shadow-sm">
                          {currentUserCart.cartItems.length}
                        </span>
                      )}
                    </div>
                    <span className="d-inline d-sm-none h6 text-white fw-semibold m-0">
                      My Cart
                    </span>
                  </Navbar.Brand>
                </Nav.Link>
              </>
            )}

            {isAuthenticated && isOnProfilePage && <Logout />}

            {!isAuthenticated && !isOnSignupPage && !isOnLoginPage && (
              <>
                <Nav.Link
                  as={Link}
                  to="/signup"
                  className="text-white fw-semibold"
                >
                  Sign Up
                </Nav.Link>
                <div className="border-end d-md-block d-sm-none mx-2"></div>
                <div className="border-bottom d-sm-block d-md-none"></div>
                <Nav.Link
                  as={Link}
                  to="/login"
                  className="text-white fw-semibold"
                >
                  Login
                </Nav.Link>
              </>
            )}

            {isOnSignupPage && (
              <p className="text-white m-0 fw-semibold">Signing Up...</p>
            )}
            {isOnLoginPage && (
              <p className="text-white m-0 fw-semibold">Logging In...</p>
            )}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}
