import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import { Button } from "react-bootstrap";
import { logoutUser } from "../../services/userServices";
import { AuthContext } from "../../contexts/AuthContext";
import { showErrorToast, showSuccessToast } from "../common/ToastAlert";

export default function Logout() {
  const navigate = useNavigate();
  const [, , removeCookie] = useCookies(["access_token", "CURRENT_USER"]);
  const { setIsAuthenticated, setCurrentUser } = useContext(AuthContext);

  const handleLogout = async () => {
    try {
      const { message } = await logoutUser();

      const cookieOptions = { path: "/" };
      removeCookie("access_token", cookieOptions);
      removeCookie("CURRENT_USER", cookieOptions);
      setIsAuthenticated(false);
      setCurrentUser(null);

      showSuccessToast(message);
      navigate("/");
    } catch (error) {
      showErrorToast(error.message || error);
    }
  };

  return (
    <div>
      <Button variant="dark" onClick={handleLogout} className="fw-semibold">
        Logout
      </Button>
    </div>
  );
}
