import React, { useState, useEffect } from "react";
import { Container, Row, Col, Spinner } from "react-bootstrap";

export default function LoadingPage({ children }) {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 3000);
    return () => clearTimeout(timer);
  }, []);

  if (isLoading) {
    return (
      <Container className="vh-100 d-flex align-items-center justify-content-center">
        <Row>
          <Col xs={12} className="text-center">
            <Spinner animation="border" variant="primary" />
            <p className="mt-3">Loading, please wait...</p>
          </Col>
        </Row>
      </Container>
    );
  } else {
    return <>{children}</>;
  }
}
