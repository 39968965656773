import Swal from "sweetalert2";

export const showErrorAlert = (errorMessage) => {
  const titles = [
    "Oopsie Poopsie!",
    "Uh oh spaghetti-o!",
    "Well, butter my butt and call me a biscuit!",
    "Shiver me timbers, that's an error!",
    "Holy guacamole, something went wrong!",
  ];
  const randomIndex = Math.floor(Math.random() * titles.length);
  const randomTitle = titles[randomIndex];
  Swal.fire({
    icon: "error",
    title: randomTitle,
    text: errorMessage,
  });
};

export const showSuccessAlert = (message) => {
  const titles = [
    "Way to go!",
    "You rock!",
    "Hooray!",
    "Great job!",
    "You did it!",
  ];
  const randomIndex = Math.floor(Math.random() * titles.length);
  const randomTitle = titles[randomIndex];
  Swal.fire({
    icon: "success",
    title: randomTitle,
    text: message,
  });
};

export const showWarningAlert = (title, message, callback) => {
  Swal.fire({
    icon: "warning",
    title: title,
    text: message,
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Confirm",
  }).then((result) => {
    if (result.isConfirmed) {
      callback();
    }
  });
};
