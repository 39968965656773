import React, { useContext, useState } from "react";
import { Container, Form, Button } from "react-bootstrap";
import { Pencil } from "react-bootstrap-icons";
import { AuthContext } from "../../contexts/AuthContext";
import ProfileEditModals from "./ProfileEditModals";
import { UserUpdateContext } from "../../contexts/UserUpdateContext";

export default function ProfileDetails() {
  const { currentUser } = useContext(AuthContext);
  const { removeUpdatedUser } = useContext(UserUpdateContext);

  const [showEditProfileModal, setShowEditProfileModal] = useState(false);
  const [showEditPasswordModal, setShowEditPasswordModal] = useState(false);

  const handleCloseEditProfileModal = () => setShowEditProfileModal(false);
  const handleShowEditProfileModal = () => {
    setShowEditProfileModal(true);
    removeUpdatedUser(currentUser._id);
  };

  const handleCloseEditPasswordModal = () => setShowEditPasswordModal(false);
  const handleShowEditPasswordModal = () => {
    setShowEditPasswordModal(true);
    removeUpdatedUser(currentUser._id);
  };

  return (
    <Container className="p-md-5 p-3 bg-white rounded-0">
      <div className="d-flex justify-content-between align-items-center">
        <div>
          <h3>My Profile</h3>
          <p>
            {currentUser.isAdmin
              ? "Behold, the royal profile of Your Highness! 😎👑"
              : `Hey there, ${currentUser.firstName}! Hop on in and dive into the amusing depths of your profile! 🤪🌊!`}
          </p>
        </div>
        <Button
          variant="white"
          type="submit"
          onClick={handleShowEditProfileModal}
          className="fw-semibold text-danger border-0 btn-outline-light px-md-5 py-md-2"
        >
          <Pencil /> Edit
        </Button>
        <ProfileEditModals
          showEditProfileModal={showEditProfileModal}
          handleCloseEditProfileModal={handleCloseEditProfileModal}
          showEditPasswordModal={showEditPasswordModal}
          handleCloseEditPasswordModal={handleCloseEditPasswordModal}
        />
      </div>
      <hr />
      <div>
        <Form className="d-flex flex-column justify-content-evenly py-md-3 px-md-4">
          <Form.Floating className="mb-1">
            <Form.Control
              className="border-0 border-bottom bg-white rounded-0 ps-md-4"
              type="text"
              placeholder="First Name"
              name="firstName"
              value={currentUser.firstName}
              disabled
            />
            <Form.Label>First Name</Form.Label>
          </Form.Floating>

          <Form.Floating className="my-1">
            <Form.Control
              className="border-0 border-bottom bg-white rounded-0 ps-md-4"
              type="text"
              placeholder="Last Name"
              name="lastName"
              value={currentUser.lastName}
              disabled
            />
            <Form.Label>Last Name</Form.Label>
          </Form.Floating>

          <Form.Floating className="my-1">
            <Form.Control
              className="border-0 border-bottom bg-white rounded-0 ps-md-4"
              type="text"
              placeholder="Mobile Number"
              name="mobileNo"
              value={currentUser.mobileNo}
              disabled
            />
            <Form.Label>Mobile Number</Form.Label>
          </Form.Floating>

          <Form.Floating className="my-1">
            <Form.Control
              className="border-0 border-bottom bg-white rounded-0 ps-md-4"
              type="text"
              placeholder="Address"
              name="address"
              value={currentUser.address}
              disabled
            />
            <Form.Label>Address</Form.Label>
          </Form.Floating>

          <Form.Floating className="my-1">
            <Form.Control
              className="border-0 border-bottom bg-white rounded-0 ps-md-4"
              type="email"
              placeholder="Email Address"
              name="email"
              value={currentUser.email}
              disabled
            />
            <Form.Label>Email address</Form.Label>
          </Form.Floating>
        </Form>
        <div className="text-center m-0">
          <Button
            variant="white"
            type="submit"
            onClick={handleShowEditPasswordModal}
            className="fw-semibold text-danger border-0 btn-outline-light"
          >
            <Pencil /> Change password?
          </Button>
        </div>
      </div>
    </Container>
  );
}
