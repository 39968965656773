import axios from "axios";
import * as config from "../config";

// fetch all orders from api
export const fetchAllOrders = async () => {
  try {
    const response = await axios.get(`${config.apiBaseUrl}/api/orders`, {
      withCredentials: true,
    });
    return response.data;
  } catch (error) {
    console.error("error", error);
    console.error("error.response", error.response);
    console.error("error.response.data", error.response.data);
    if (error.response.data.hasOwnProperty("details")) {
      const errorMessage = `${error.response.data.message} ${error.response.data.details}`;
      throw new Error(errorMessage || "Unable to fetch all orders");
    } else {
      throw new Error(
        error.response.data.message || "Unable to fetch all orders"
      );
    }
  }
};

// fetch an order from api
export const fetchOrder = async (userId) => {
  try {
    const response = await axios.get(
      `${config.apiBaseUrl}/api/orders/${userId}`,
      {
        withCredentials: true,
      }
    );
    return response.data;
  } catch (error) {
    console.error("error", error);
    console.error("error.response", error.response);
    console.error("error.response.data", error.response.data);
    if (error.response.data.hasOwnProperty("details")) {
      const errorMessage = `${error.response.data.message} ${error.response.data.details}`;
      throw new Error(errorMessage || "Unable to fetch orders");
    } else {
      throw new Error(error.response.data.message || "Unable to fetch orders");
    }
  }
};

// createOrder
export const createOrder = async (createdOrder) => {
  try {
    const response = await axios.post(
      `${config.apiBaseUrl}/api/orders`,
      createdOrder,
      {
        withCredentials: true,
      }
    );
    return response.data;
  } catch (error) {
    console.error("error", error);
    console.error("error.response", error.response);
    console.error("error.response.data", error.response.data);
    if (error.response.data.hasOwnProperty("details")) {
      const errorMessage = `${error.response.data.message} ${error.response.data.details}`;
      throw new Error(errorMessage || "Unable to create orders");
    } else {
      throw new Error(error.response.data.message || "Unable to create orders");
    }
  }
};

// updateOrder
export const updateOrder = async (updateStatus) => {
  try {
    const response = await axios.put(
      `${config.apiBaseUrl}/api/orders`,
      updateStatus,
      {
        withCredentials: true,
      }
    );
    return response.data;
  } catch (error) {
    console.error("error", error);
    console.error("error.response", error.response);
    console.error("error.response.data", error.response.data);
    if (error.response.data.hasOwnProperty("details")) {
      const errorMessage = `${error.response.data.message} ${error.response.data.details}`;
      throw new Error(errorMessage || "Unable to update orders");
    } else {
      throw new Error(error.response.data.message || "Unable to update orders");
    }
  }
};
